/**
 *	Reset
  *	Repeatable Patterns
  *	Header
  * Navbar Top
  * Vertical Navigation
  *	Member Status
  * Main
  *	Box
  *	Legend
  *	Box Message
  *	Message Info
  *	Preload
*/
// @import "reset";
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Helvetica", "Montserrat", sans-serif;
  line-height: 1;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

/* Status Bar */

.status-bar ul li {
  display: inline-block;
  width: calc(25% - 6px);
  margin-right: 4px;
  border-radius: 8px;
  font-family: "Helvetica", "Montserrat", sans-serif;
  /* box-shadow: inset 0px 0px 90px 0px rgba(0,0,0,0.18); */
  background-color: #242629;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;

  &:last-child {
    margin-right: 0px;
  }

  .icon {
    float: left;
    line-height: 84px;
    height: 84px;
    padding: 0 30px 0 27px;
    margin-right: 18px;
    background-color: #1d1f23;
  }

  .content {
    .numb {
      font-family: "Helvetica", "Montserrat", sans-serif;
      font-size: 54px;
      font-weight: 500;
      line-height: 80px;
      color: #f9f9f9;
      float: left;
      margin-right: 18px;
    }

    .text {
      font-family: "Helvetica", "Montserrat", sans-serif;
      font-size: 14px;
      font-weight: 200;
      color: #f0f0f0;
      line-height: 80px;
    }
  }
}

@import "box";
@import "legend";

/* Chart Div */
#chartdiv {
  margin-bottom: 15px;
}

canvas {
  /* width: 100% !important; */
  top: 0;
  left: 0;
}

/* Box Project */

.box-project .box-content {
  table {
    thead tr {
      border-bottom: 1px solid #29292a;

      th {
        padding: 25px 0 17px;
        text-align: left;
        vertical-align: middle;
      }

      td {
        padding: 25px 0 17px;
        text-align: left;
        vertical-align: middle;
        padding: 20px 0 18px;
        font-family: "Helvetica", "Montserrat", sans-serif;
        font-size: 12px;
        font-weight: 400;
      }

      th {
        font-weight: 500;
        font-family: "Helvetica", "Montserrat", sans-serif;

        &:nth-child(2) {
          padding-left: 19px;
          width: 35%;
        }

        &:nth-child(3) {
          padding-left: 48px;
        }

        &:nth-child(4) {
          text-align: right;
          padding-right: 4px;
        }
      }

      td {
        &:nth-child(1) {
          width: 7.5%;
        }

        &:nth-child(4) {
          width: 15.3%;
          vertical-align: middle;
        }
      }
    }

    width: 620px;
    margin-top: 5px;
    overflow: hidden;

    tbody tr {
      td.bg {
        padding: 8px 0;

        span {
          background-color: #4c418b;
          font-size: 12px;
          font-weight: 500;
          height: 37px;
          line-height: 40px;
          color: #fff;
          padding-left: 48px;
          display: inline-block;
        }
      }

      &.design td.bg span,
      &.testing td.bg span {
        background-color: #00bcd5;
      }

      &.cancel td {
        &.bg span {
          background-color: #c73939;
        }

        &:nth-child(4) {
          color: #fff;
        }
      }
    }
  }

  overflow-x: hidden;
  width: 100%;
  height: auto;
  position: relative;
}

/* Box Inbox */

.box-inbox .box-content ul {
  margin-top: 17px;

  li {
    // font-family: "Helvetica", "Montserrat", sans-serif;
    padding: 14px 0 13px;
    // border-bottom: 1px solid #222426;
    margin-left: -3px;
    display: block;

    a {
      display: block;
      color: #898989;
    }

    .left {
      float: left;

      img {
        border-radius: 50%;
        float: left;
        margin-right: 28px;
      }

      .info {
        font-size: 10px;
        float: left;
        margin-top: 2px;

        .name {
          font-size: 12px;
          color: var(--bs-body-color);
          margin-bottom: 7px;
        }
      }
    }

    .right {
      float: right;
      font-size: 10px;
      position: relative;
      right: 42px;
      top: 5px;
    }
  }
}

/* Main Message
-------------------------------------------------------------- */
section.main-content,
#message,
#calendar {
  // overflow: auto;
  padding: 0 20px 0px;
  // display: none;
}

/* Box Message
-------------------------------------------------------------- */

.box-message {
  box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.5);
  border-radius: 0px;
  border-top-left-radius: 7px;
  overflow: hidden;
  padding: 0;
  width: 425px;
  margin-bottom: 0px;
  background: none;

  .box-header {
    margin-bottom: 0px;

    .header-title {
      line-height: 100px;
      font-family: "Helvetica", "Montserrat", sans-serif;
      font-weight: 500;
      color: #ebebeb;
      padding: 0 35px;
      background-color: #574c9d;
      position: relative;
      cursor: pointer;

      &:before {
        content: "\f107";
        position: absolute;
        font-family: "Helvetica", "Montserrat", sans-serif;
        right: 54px;
        top: 3px;
        font-size: 19px;
      }
    }

    &.active .header-title:before {
      content: "\f106";
    }

    .header-title span {
      margin-left: 21px;
      overflow: visible !important;
    }
  }

  .box-content {
    height: 837px;
    background: -moz-linear-gradient(
      90deg,
      rgba(24, 25, 28, 1) 0%,
      rgba(32, 34, 38, 1) 65%,
      rgba(32, 34, 38, 1) 100%
    );

    /* ff3.6+ */
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0%, rgba(32, 34, 38, 1)),
      color-stop(35%, rgba(32, 34, 38, 1)),
      color-stop(100%, rgba(24, 25, 28, 1))
    );

    /* safari4+,chrome */
    background: -webkit-linear-gradient(
      90deg,
      rgba(24, 25, 28, 1) 0%,
      rgba(32, 34, 38, 1) 65%,
      rgba(32, 34, 38, 1) 100%
    );

    /* safari5.1+,chrome10+ */
    background: -o-linear-gradient(
      90deg,
      rgba(24, 25, 28, 1) 0%,
      rgba(32, 34, 38, 1) 65%,
      rgba(32, 34, 38, 1) 100%
    );

    /* opera 11.10+ */
    background: -ms-linear-gradient(
      90deg,
      rgba(24, 25, 28, 1) 0%,
      rgba(32, 34, 38, 1) 65%,
      rgba(32, 34, 38, 1) 100%
    );

    /* ie10+ */
    background: linear-gradient(
      0deg,
      rgba(24, 25, 28, 1) 0%,
      rgba(32, 34, 38, 1) 65%,
      rgba(32, 34, 38, 1) 100%
    );

    /* w3c */

    ul {
      height: 630px;
      overflow: hidden;

      li {
        cursor: pointer;
        display: block;
        padding: 40px 35px 25px 30px;
        border-bottom: 1px solid #2e3033;
        position: relative;

        &.active {
          background-color: var(--bs-secondary-bg);

          &:before {
            content: "";
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            width: 4px;
            background-color: #04aec6;
          }
        }

        .left {
          float: left;

          .avatar {
            float: left;
            margin-right: 30px;
            margin-top: 3px;
            position: relative;

            img {
              border-radius: 50%;
              width: 49px;
            }
          }

          .content {
            float: left;

            .username {
              position: relative;
              display: inline-block;

              span {
                position: absolute;
                top: -30px;
                right: -28px;
                height: 25px;
                width: 25px;
                line-height: 25px;
                text-align: center;
                color: #fff;
                font-size: 14px;
                font-weight: 700;
                background-color: #04aec6;
                border-radius: 50%;

                &:before {
                  content: "";
                  position: absolute;
                  height: 38px;
                  width: 38px;
                  background-color: transparent;
                  border: 4px solid #04aec6;
                  top: -6px;
                  left: -6px;
                  border-radius: 50%;
                  animation: heartbit 1s ease-out;
                  animation-iteration-count: infinite;
                  -moz-animation: heartbit 1s ease-out;
                  -moz-animation-iteration-count: infinite;
                  -o-animation: heartbit 1s ease-out;
                  -o-animation-iteration-count: infinite;
                  -webkit-animation: heartbit 1s ease-out;
                  -webkit-animation-iteration-count: infinite;
                  animation-iteration-count: infinite;
                }
              }
            }
          }
        }
      }
    }
  }
}

.heartbit {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    height: 38px;
    width: 38px;
    background-color: transparent;
    border: 4px solid #04aec6;
    top: -6px;
    left: -6px;
    border-radius: 50%;
    display: none;
    animation: heartbit 1s ease-out;
    animation-iteration-count: infinite;
    -moz-animation: heartbit 1s ease-out;
    -moz-animation-iteration-count: infinite;
    -o-animation: heartbit 1s ease-out;
    -o-animation-iteration-count: infinite;
    -webkit-animation: heartbit 1s ease-out;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
  }
}

.status-color.blue.heartbit.style1:before {
  width: 21px;
  height: 21px;
  top: -6px;
  left: -6.5px;
  z-index: -1;
  display: block;
}

.box-message .box-content ul li .status-color.blue.heartbit.style2:before {
  display: none;
}

.member-status.right .sidebar-member ul.member-list li .status-color {
  &.heartbit:before
    .member-status.right
    .sidebar-member
    ul.member-list
    li
    .status-color.green.heartbit:before,
  &.pink.heartbit:before,
  &.grey.heartbit:before {
    display: none;
  }
}

.box-message
  .box-content
  ul
  li:hover
  .status-color.blue.heartbit.style2:before {
  display: block;
  width: 18px;
  height: 18px;
  top: -5px;
  left: -5px;
  z-index: -1;
}

.member-status.right .sidebar-member ul.member-list li:hover .status-color {
  &.heartbit:before {
    display: block;
    width: 18px;
    height: 18px;
    top: -5px;
    left: -5px;
    z-index: -1;
    border: 4px solid #89e07d;
  }

  &.blue.heartbit:before {
    border: 4px solid #04aec6;
  }

  &.pink.heartbit:before {
    border: 4px solid #c73939;
  }

  &.grey.heartbit:before {
    border: 4px solid #c9c9c9;
  }
}

@keyframes heartbit {
  0% {
    -webkit-transform: scale(0);
    opacity: 0;
  }

  25% {
    -webkit-transform: scale(0.1);
    opacity: 0.1;
  }

  50% {
    -webkit-transform: scale(0.5);
    opacity: 0.3;
  }

  75% {
    -webkit-transform: scale(0.8);
    opacity: 0.6;
  }

  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}

.box-message .box-content ul li {
  .left .content {
    .username .name {
      font-size: 16px;
      font-weight: 600;
      color: #ebebeb;
      margin-bottom: 5px;
    }

    .text p {
      font-size: 12px;
      line-height: 18px;
    }
  }

  .right {
    float: right;

    .date {
      font-size: 12px;
      margin-top: 3px;
    }
  }
}

.new-message {
  margin-top: 30px;
  text-align: center;

  a {
    display: inline-block;
    line-height: 62px;
    height: 60px;
    padding: 0 45px 0 100px;
    color: #ebebeb;
    font-size: 16px;
    font-weight: 400;
    border: 1px solid #252528;
    position: relative;
    border-radius: 4px;
    letter-spacing: 0.3px;

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 60px;
      height: 1px;
      width: 15px;
      background-color: #fff;
    }

    &:after {
      content: "";
      position: absolute;
      top: 39%;
      left: 67px;
      height: 15px;
      width: 1px;
      background-color: #fff;
    }
  }
}

/* Mesage Info
-------------------------------------------------------------- */

.message-info {
  float: left;
  border-top-right-radius: 7px;
  overflow: hidden;
  width: calc(100% - 425px);

  .message-header {
    background-color: #fff;

    .move-message {
      line-height: 106px;
      height: 100px;
      text-align: right;
      padding-right: 52px;
      letter-spacing: 0.6px;

      span {
        margin-right: 15px;
      }
    }

    .box-info-messager {
      padding-left: 50px;
      padding-bottom: 29px;

      .message-pic {
        float: left;
        margin-right: 31px;
        position: relative;

        img {
          border-radius: 50%;
          max-width: 50px;
        }
      }

      .content {
        .username {
          color: #232323;
          font-weight: 600;
          font-size: 16px;
          margin-bottom: 4px;
        }

        .text p {
          line-height: 18px;
          color: #6c6b6b;
          font-size: 12px;
        }
      }
    }
  }

  .message-box {
    background-color: #ededed;
    height: 664px;
    overflow: hidden;

    .mCSB_inside > .mCSB_container {
      padding: 20px 50px 0px;
    }

    .message-in {
      float: left;
      text-align: left;
      margin-bottom: 20px;
      width: 100%;

      .message-pic {
        float: left;
        margin-right: 32px;
        margin-top: 3px;
        position: relative;

        img {
          border-radius: 50%;
          margin-top: 5px;
          width: 50px;
        }
      }

      .message-body {
        float: left;

        .message-text {
          width: 348px;
          padding: 17px 30px 18px;
          float: left;
          background-color: #fff;
          border-radius: 6px;
          font-size: 12px;
          position: relative;
          word-break: break-word;
          box-shadow: 4px 3px 5px 0px rgba(0, 0, 0, 0.05);

          &:before {
            content: "";
            position: absolute;
            left: -20px;
            top: 10px;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-right: 20px solid #fff;
          }

          p {
            line-height: 20px;
            word-wrap: break-word;
          }
        }
      }
    }

    .message-out .message-body .message-text p {
      line-height: 20px;
      word-wrap: break-word;
    }

    .message-in .message-body .message-image ul li {
      display: inline-block;
      margin: 10px 5px 0px;
    }

    .message-out {
      .message-body .message-image ul li {
        display: inline-block;
        margin: 10px 5px 0px;
      }

      float: right;
      text-align: right;
      margin-bottom: 20px;
      width: 100%;

      .message-pic {
        float: right;
        margin-left: 51px;
        margin-top: 7px;
        position: relative;

        img {
          border-radius: 50%;
          width: 50px;
        }
      }

      .message-body {
        float: right;

        .message-text {
          width: 410px;
          padding: 16px 30px 19px;
          background-color: #fff;
          border-radius: 5px;
          font-size: 12px;
          position: relative;
          box-shadow: 4px 3px 5px 0px rgba(0, 0, 0, 0.05);

          &:before {
            content: "";
            position: absolute;
            right: -20px;
            top: 10px;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-left: 20px solid #fff;
          }
        }
      }
    }
  }

  .form-chat {
    background-color: #fff;
    padding: 0px 50px;

    form .message-form-chat span {
      float: left;
      margin-right: 20px;
      line-height: 85px;

      &.pin {
        margin-right: 10px;
        width: 10px;
      }

      &.btn-send {
        margin-right: 0;
        height: 85px;
        display: block;
        line-height: 86px;
      }

      &.message-text {
        line-height: 1;
        margin-top: 16px;
        width: calc(100% - 243px);
      }

      button {
        display: inline-block;
        line-height: 32px;
        color: #6c6c6c;
        font-size: 16px;
        padding: 0 20px 0 22px;
        border: 1px solid #e7e7e7;
        border-radius: 15px;
      }
    }
  }
}

.icon-mobile {
  display: none;
  float: left;
  line-height: 85px;
}

@import "calendar";

/* Notify */

ul.notify li {
  padding: 20px;
  border-bottom: 1px solid #29292a;

  .avatar {
    border-radius: 50%;
    overflow: hidden;
    float: left;
    margin-right: 15px;
  }

  .notify-content {
    margin-top: 5px;
  }
}

.divider35 {
  height: 35px;
}

.divider22 {
  height: 22px;
}

.divider50 {
  height: 50px;
}

/* Preload
-------------------------------------------------------------- */
// .loader {
//   position: fixed;
//   background-color: #ffffff;
//   width: 100%;
//   height: 100%;
//   z-index: 9999999;
// }

// .loader.inner {
//   position: absolute;
//   box-sizing: border-box;
//   width: 64px;
//   height: 64px;
//   top: 40%;
//   left: 50%;
//   margin-left: -32px;
//   transform: translateX(-50%);
//   border-radius: 50%;
// }

// .loader.inner.one {
//   animation: rotate-one 1s linear infinite;
//   border-bottom: 3px solid #247abb;
// }

// .loader.inner.two {
//   animation: rotate-two 1s linear infinite;
//   border-right: 3px solid #247abb;
// }

// .loader.inner.three {
//   animation: rotate-three 1s linear infinite;
//   border-top: 3px solid #247abb;
// }

@keyframes rotate-one {
  0% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }

  100% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}

@keyframes rotate-two {
  0% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }

  100% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}

@keyframes rotate-three {
  0% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }

  100% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}

.content.scroll.mCustomScrollbar .notify .avatar img.mCS_img_loaded {
  width: 50px;
}

.table-responsive {
  @media only screen and (max-width: 1200px) {
    display: block;
  }
}

.nav-tabs .nav-link {
  letter-spacing: 1px;
}
