$background-color_1: #000;
$background-color_2: rgba(0,0,0,0.4);
$background-color_3: #fff;
$background-color_4: rgba(140,140,140,0.75);
$background-color_5: rgba(140,140,140,0.85);
$background-color_6: rgba(140,140,140,0.9);
$background-color_7: rgba(0,0,0,0.15);
$background-color_8: rgba(0,0,0,0.75);
$background-color_9: rgba(0,0,0,0.85);
$background-color_10: rgba(0,0,0,0.9);
$background-color_11: rgba(255,255,255,0.1);
$background-color_12: rgba(255,255,255,0.75);
$background-color_13: rgba(255,255,255,0.85);
$background-color_14: rgba(255,255,255,0.9);
$background-color_15: rgba(0,0,0,0.1);
$background-color_16: rgba(255,255,255,0.15);
$background-color_17: transparent;
$background-color_18: rgba(0,0,0,0.2);
$background-color_19: #555;
$background-color_20: rgba(0,0,0,0.05);
$background-color_21: #777;
$background-color_22: rgba(255,255,255,0.2);
$background-color_23: rgba(255,255,255,0.5);
$background-color_24: rgba(0,0,0,0.5);
$background-color_25: rgba(255,255,255,0.6);
$background-color_26: rgba(0,0,0,0.6);
$border-color_1: #fff;
$border-color_2: rgba(255,255,255,0.2);
$border-color_3: #000;
$border-color_4: rgba(0,0,0,0.2);

/*
== malihu jquery custom scrollbar plugin ==
Plugin URI: http://manos.malihu.gr/jquery-custom-content-scroller
*/
/*
CONTENTS:
	1. BASIC STYLE - Plugin's basic/essential CSS properties (normally, should not be edited).
	2. VERTICAL SCROLLBAR - Positioning and dimensions of vertical scrollbar.
	3. HORIZONTAL SCROLLBAR - Positioning and dimensions of horizontal scrollbar.
	4. VERTICAL AND HORIZONTAL SCROLLBARS - Positioning and dimensions of 2-axis scrollbars.
	5. TRANSITIONS - CSS3 transitions for hover events, auto-expanded and auto-hidden scrollbars.
	6. SCROLLBAR COLORS, OPACITY AND BACKGROUNDS
		6.1 THEMES - Scrollbar colors, opacity, dimensions, backgrounds etc. via ready-to-use themes.
*/
/*
------------------------------------------------------------------------------------------------------------------------
1. BASIC STYLE
------------------------------------------------------------------------------------------------------------------------
*/
/*
------------------------------------------------------------------------------------------------------------------------
2. VERTICAL SCROLLBAR
y-axis
------------------------------------------------------------------------------------------------------------------------
*/
/* non-visible scrollbar */
/* RTL direction/left-side scrollbar */
/* scrollbar position: outside */
/* RTL direction/left-side scrollbar (scrollbar position: outside) */
/*
------------------------------------------------------------------------------------------------------------------------
3. HORIZONTAL SCROLLBAR
x-axis
------------------------------------------------------------------------------------------------------------------------
*/
/* non-visible scrollbar */
/* scrollbar position: outside */
/*
------------------------------------------------------------------------------------------------------------------------
4. VERTICAL AND HORIZONTAL SCROLLBARS
yx-axis
------------------------------------------------------------------------------------------------------------------------
*/
/* non-visible horizontal scrollbar */
/* non-visible vertical scrollbar/RTL direction/left-side scrollbar */
/* RTL direction/left-side scrollbar */
/* non-visible scrollbar/RTL direction/left-side scrollbar */
/* non-visible horizontal scrollbar */
/*
------------------------------------------------------------------------------------------------------------------------
5. TRANSITIONS
------------------------------------------------------------------------------------------------------------------------
*/
/* auto-expanded scrollbar */
/*
------------------------------------------------------------------------------------------------------------------------
6. SCROLLBAR COLORS, OPACITY AND BACKGROUNDS
------------------------------------------------------------------------------------------------------------------------
*/
/*
	----------------------------------------
	6.1 THEMES
	----------------------------------------
	*/
/* default theme ("light") */
/* theme: "dark" */
/* ---------------------------------------- */
/* theme: "light-2", "dark-2" */
/* theme: "dark-2" */
/* ---------------------------------------- */
/* theme: "light-thick", "dark-thick" */
/* theme: "dark-thick" */
/* ---------------------------------------- */
/* theme: "light-thin", "dark-thin" */
/* theme "dark-thin" */
/* ---------------------------------------- */
/* theme "rounded", "rounded-dark", "rounded-dots", "rounded-dots-dark" */
/* theme "rounded-dark", "rounded-dots-dark" */
/* theme "rounded-dots", "rounded-dots-dark" */
/* theme "rounded-dots-dark" */
/* ---------------------------------------- */
/* theme "3d", "3d-dark", "3d-thick", "3d-thick-dark" */
/* theme "3d", "3d-dark" */
/* theme "3d-dark" */
/* ---------------------------------------- */
/* theme: "3d-thick", "3d-thick-dark" */
/* theme: "3d-thick-dark" */
/* ---------------------------------------- */
/* theme: "minimal", "minimal-dark" */
/* RTL direction/left-side scrollbar */
/* theme: "minimal-dark" */
/* ---------------------------------------- */
/* theme "light-3", "dark-3" */
/* theme "dark-3" */
/* ---------------------------------------- */
/* theme "inset", "inset-dark", "inset-2", "inset-2-dark", "inset-3", "inset-3-dark" */
/* theme "inset-dark", "inset-2-dark", "inset-3-dark" */
/* theme "inset-2", "inset-2-dark" */
/* theme "inset-3", "inset-3-dark" */
/* ---------------------------------------- */
.mCustomScrollbar {
	-ms-touch-action: pinch-zoom;
	touch-action: pinch-zoom;
	>.mCustomScrollBox {
		>.mCSB_scrollTools.mCSB_scrollTools_onDrag {
			opacity: 1;
			filter: "alpha(opacity=100)";
			-ms-filter: "alpha(opacity=100)";
		}
		~ {
			.mCSB_scrollTools.mCSB_scrollTools_onDrag {
				opacity: 1;
				filter: "alpha(opacity=100)";
				-ms-filter: "alpha(opacity=100)";
			}
		}
	}
}
.mCustomScrollbar.mCS_no_scrollbar {
	-ms-touch-action: auto;
	touch-action: auto;
}
.mCustomScrollbar.mCS_touch_action {
	-ms-touch-action: auto;
	touch-action: auto;
}
.mCustomScrollBox {
	position: relative;
	overflow: hidden;
	height: 100%;
	max-width: 100%;
	outline: none;
	direction: ltr;
	+ {
		.mCSB_scrollTools.mCSB_scrollTools_horizontal {
			bottom: -26px;
		}
		.mCSB_scrollTools {
			+ {
				.mCSB_scrollTools.mCSB_scrollTools_horizontal {
					bottom: -26px;
				}
			}
		}
	}
	&:hover {
		>.mCSB_scrollTools {
			opacity: 1;
			filter: "alpha(opacity=100)";
			-ms-filter: "alpha(opacity=100)";
		}
		~ {
			.mCSB_scrollTools {
				opacity: 1;
				filter: "alpha(opacity=100)";
				-ms-filter: "alpha(opacity=100)";
			}
		}
	}
}
.mCSB_container {
	overflow: hidden;
	width: auto;
	height: auto;
}
.mCSB_inside {
	>.mCSB_container {
		margin-right: 0px;
	}
	+ {
		.mCS-3d-thick.mCSB_scrollTools_vertical {
			right: 1px;
		}
		.mCS-3d-thick-dark.mCSB_scrollTools_vertical {
			right: 1px;
		}
	}
}
.mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
	margin-right: 0;
}
.mCS-dir-rtl {
	>.mCSB_inside {
		>.mCSB_container {
			margin-right: 0;
			margin-left: 30px;
		}
		>.mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
			margin-left: 0;
		}
		>.mCSB_scrollTools {
			right: auto;
			left: 0;
		}
		>.mCSB_container_wrapper {
			margin-right: 0;
			margin-left: 30px;
		}
	}
	>.mCSB_outside {
		+ {
			.mCSB_scrollTools {
				right: auto;
				left: 0;
				left: -26px;
			}
			.mCS-minimal.mCSB_scrollTools_vertical {
				left: 0;
				right: auto;
			}
			.mCS-minimal-dark.mCSB_scrollTools_vertical {
				left: 0;
				right: auto;
			}
		}
	}
	>.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside {
		>.mCSB_scrollTools.mCSB_scrollTools_horizontal {
			right: 0;
			left: 20px;
		}
		>.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden {
			+ {
				.mCSB_scrollTools {
					~ {
						.mCSB_scrollTools.mCSB_scrollTools_horizontal {
							left: 0;
						}
					}
				}
			}
		}
	}
}
.mCSB_scrollTools {
	position: absolute;
	width: 16px;
	height: auto;
	left: auto;
	top: 0;
	right: 0;
	bottom: 0;
	-webkit-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
	-moz-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
	-o-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
	transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
	opacity: 0.75;
	filter: "alpha(opacity=75)";
	-ms-filter: "alpha(opacity=75)";
	.mCSB_draggerContainer {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		height: auto;
	}
	a {
		+ {
			.mCSB_draggerContainer {
				margin: 20px 0;
			}
		}
	}
	.mCSB_draggerRail {
		width: 2px;
		height: 100%;
		margin: 0 auto;
		-webkit-border-radius: 16px;
		-moz-border-radius: 16px;
		border-radius: 16px;
		background-color: $background-color_1;
		background-color: $background-color_2;
		filter: "alpha(opacity=40)";
		-ms-filter: "alpha(opacity=40)";
	}
	.mCSB_dragger {
		cursor: pointer;
		width: 100%;
		height: 30px;
		z-index: 1;
		.mCSB_dragger_bar {
			position: relative;
			width: 4px;
			height: 100%;
			margin: 0 auto;
			-webkit-border-radius: 16px;
			-moz-border-radius: 16px;
			border-radius: 16px;
			text-align: center;
			-webkit-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
			-moz-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
			-o-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
			transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
			background-color: $background-color_3;
			background-color: $background-color_4;
			filter: "alpha(opacity=75)";
			-ms-filter: "alpha(opacity=75)";
		}
		&:hover {
			.mCSB_dragger_bar {
				background-color: $background-color_3;
				background-color: $background-color_5;
				filter: "alpha(opacity=85)";
				-ms-filter: "alpha(opacity=85)";
			}
		}
		&:active {
			.mCSB_dragger_bar {
				background-color: $background-color_3;
				background-color: $background-color_6;
				filter: "alpha(opacity=90)";
				-ms-filter: "alpha(opacity=90)";
			}
		}
	}
	.mCSB_buttonUp {
		display: block;
		position: absolute;
		height: 20px;
		width: 100%;
		overflow: hidden;
		margin: 0 auto;
		cursor: pointer;
		-webkit-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
		-moz-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
		-o-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
		transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
		background-image: url(mCSB_buttons.png);
		background-repeat: no-repeat;
		opacity: 0.4;
		filter: "alpha(opacity=40)";
		-ms-filter: "alpha(opacity=40)";
		background-position: 0 0;
		&:hover {
			opacity: 0.75;
			filter: "alpha(opacity=75)";
			-ms-filter: "alpha(opacity=75)";
		}
		&:active {
			opacity: 0.9;
			filter: "alpha(opacity=90)";
			-ms-filter: "alpha(opacity=90)";
		}
	}
	.mCSB_buttonDown {
		display: block;
		position: absolute;
		height: 20px;
		width: 100%;
		overflow: hidden;
		margin: 0 auto;
		cursor: pointer;
		bottom: 0;
		-webkit-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
		-moz-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
		-o-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
		transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
		background-image: url(mCSB_buttons.png);
		background-repeat: no-repeat;
		opacity: 0.4;
		filter: "alpha(opacity=40)";
		-ms-filter: "alpha(opacity=40)";
		background-position: 0 -20px;
		&:hover {
			opacity: 0.75;
			filter: "alpha(opacity=75)";
			-ms-filter: "alpha(opacity=75)";
		}
		&:active {
			opacity: 0.9;
			filter: "alpha(opacity=90)";
			-ms-filter: "alpha(opacity=90)";
		}
	}
	.mCSB_buttonLeft {
		-webkit-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
		-moz-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
		-o-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
		transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
		background-image: url(mCSB_buttons.png);
		background-repeat: no-repeat;
		opacity: 0.4;
		filter: "alpha(opacity=40)";
		-ms-filter: "alpha(opacity=40)";
		background-position: 0 -40px;
		&:hover {
			opacity: 0.75;
			filter: "alpha(opacity=75)";
			-ms-filter: "alpha(opacity=75)";
		}
		&:active {
			opacity: 0.9;
			filter: "alpha(opacity=90)";
			-ms-filter: "alpha(opacity=90)";
		}
	}
	.mCSB_buttonRight {
		-webkit-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
		-moz-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
		-o-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
		transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
		background-image: url(mCSB_buttons.png);
		background-repeat: no-repeat;
		opacity: 0.4;
		filter: "alpha(opacity=40)";
		-ms-filter: "alpha(opacity=40)";
		background-position: 0 -56px;
		&:hover {
			opacity: 0.75;
			filter: "alpha(opacity=75)";
			-ms-filter: "alpha(opacity=75)";
		}
		&:active {
			opacity: 0.9;
			filter: "alpha(opacity=90)";
			-ms-filter: "alpha(opacity=90)";
		}
	}
	.mCSB_dragger.mCSB_dragger_onDrag {
		.mCSB_dragger_bar {
			background-color: $background-color_3;
			background-color: $background-color_6;
			filter: "alpha(opacity=90)";
			-ms-filter: "alpha(opacity=90)";
		}
	}
}
.mCSB_outside {
	+ {
		.mCSB_scrollTools {
			right: -26px;
		}
		.mCS-minimal.mCSB_scrollTools_vertical {
			right: 0;
			margin: 12px 0;
		}
		.mCS-minimal-dark.mCSB_scrollTools_vertical {
			right: 0;
			margin: 12px 0;
		}
	}
}
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand {
	.mCSB_dragger.mCSB_dragger_onDrag_expanded {
		.mCSB_dragger_bar {
			width: 12px;
		}
		+ {
			.mCSB_draggerRail {
				width: 8px;
			}
		}
	}
	.mCSB_draggerContainer {
		&:hover {
			.mCSB_dragger {
				.mCSB_dragger_bar {
					width: 12px;
				}
			}
			.mCSB_draggerRail {
				width: 8px;
			}
		}
	}
	.mCSB_dragger_bar {
		-webkit-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
		-moz-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
		-o-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
		transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
	}
	.mCSB_draggerRail {
		-webkit-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
		-moz-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
		-o-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
		transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
	}
}
.mCSB_horizontal.mCSB_inside {
	>.mCSB_container {
		margin-right: 0;
		margin-bottom: 30px;
	}
}
.mCSB_horizontal.mCSB_outside {
	>.mCSB_container {
		min-height: 100%;
	}
}
.mCSB_horizontal {
	>.mCSB_container.mCS_no_scrollbar_x.mCS_x_hidden {
		margin-bottom: 0;
	}
}
.mCSB_scrollTools.mCSB_scrollTools_horizontal {
	width: auto;
	height: 16px;
	top: auto;
	right: 0;
	bottom: 0;
	left: 0;
	a {
		+ {
			.mCSB_draggerContainer {
				margin: 0 20px;
			}
		}
	}
	.mCSB_draggerRail {
		width: 100%;
		height: 2px;
		margin: 7px 0;
	}
	.mCSB_dragger {
		width: 30px;
		height: 100%;
		left: 0;
		.mCSB_dragger_bar {
			width: 100%;
			height: 4px;
			margin: 6px auto;
		}
	}
	.mCSB_buttonLeft {
		display: block;
		position: absolute;
		width: 20px;
		height: 100%;
		overflow: hidden;
		margin: 0 auto;
		cursor: pointer;
		left: 0;
	}
	.mCSB_buttonRight {
		display: block;
		position: absolute;
		width: 20px;
		height: 100%;
		overflow: hidden;
		margin: 0 auto;
		cursor: pointer;
		right: 0;
	}
}
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand {
	.mCSB_dragger.mCSB_dragger_onDrag_expanded {
		.mCSB_dragger_bar {
			height: 12px;
			margin: 2px auto;
		}
		+ {
			.mCSB_draggerRail {
				height: 8px;
				margin: 4px 0;
			}
		}
	}
	.mCSB_draggerContainer {
		&:hover {
			.mCSB_dragger {
				.mCSB_dragger_bar {
					height: 12px;
					margin: 2px auto;
				}
			}
			.mCSB_draggerRail {
				height: 8px;
				margin: 4px 0;
			}
		}
	}
	.mCSB_dragger_bar {
		-webkit-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
		-moz-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
		-o-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
		transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
	}
	.mCSB_draggerRail {
		-webkit-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
		-moz-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
		-o-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
		transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
	}
}
.mCSB_container_wrapper {
	position: absolute;
	height: auto;
	width: auto;
	overflow: hidden;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin-right: 30px;
	margin-bottom: 30px;
	>.mCSB_container {
		padding-right: 30px;
		padding-bottom: 30px;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
	}
}
.mCSB_vertical_horizontal {
	>.mCSB_scrollTools.mCSB_scrollTools_vertical {
		bottom: 20px;
	}
	>.mCSB_scrollTools.mCSB_scrollTools_horizontal {
		right: 20px;
	}
}
.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden {
	+ {
		.mCSB_scrollTools.mCSB_scrollTools_vertical {
			bottom: 0;
		}
	}
	>.mCSB_container {
		padding-bottom: 0;
	}
}
.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden {
	+ {
		.mCSB_scrollTools {
			~ {
				.mCSB_scrollTools.mCSB_scrollTools_horizontal {
					right: 0;
				}
			}
		}
	}
	>.mCSB_container {
		padding-right: 0;
	}
}
.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside {
	>.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden {
		margin-right: 0;
		margin-left: 0;
	}
	>.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden {
		margin-bottom: 0;
	}
}
.mCS-autoHide {
	>.mCustomScrollBox {
		>.mCSB_scrollTools {
			opacity: 0;
			filter: "alpha(opacity=0)";
			-ms-filter: "alpha(opacity=0)";
		}
		~ {
			.mCSB_scrollTools {
				opacity: 0;
				filter: "alpha(opacity=0)";
				-ms-filter: "alpha(opacity=0)";
			}
		}
	}
	&:hover {
		>.mCustomScrollBox {
			>.mCSB_scrollTools {
				opacity: 1;
				filter: "alpha(opacity=100)";
				-ms-filter: "alpha(opacity=100)";
			}
			~ {
				.mCSB_scrollTools {
					opacity: 1;
					filter: "alpha(opacity=100)";
					-ms-filter: "alpha(opacity=100)";
				}
			}
		}
	}
}
.mCS-dark.mCSB_scrollTools {
	.mCSB_draggerRail {
		background-color: $background-color_1;
		background-color: $background-color_7;
	}
	.mCSB_dragger {
		.mCSB_dragger_bar {
			background-color: $background-color_1;
			background-color: $background-color_8;
		}
		&:hover {
			.mCSB_dragger_bar {
				background-color: $background-color_9;
			}
		}
		&:active {
			.mCSB_dragger_bar {
				background-color: $background-color_10;
			}
		}
	}
	.mCSB_dragger.mCSB_dragger_onDrag {
		.mCSB_dragger_bar {
			background-color: $background-color_10;
		}
	}
	.mCSB_buttonUp {
		background-position: -80px 0;
	}
	.mCSB_buttonDown {
		background-position: -80px -20px;
	}
	.mCSB_buttonLeft {
		background-position: -80px -40px;
	}
	.mCSB_buttonRight {
		background-position: -80px -56px;
	}
}
.mCS-light-2.mCSB_scrollTools {
	.mCSB_draggerRail {
		width: 4px;
		background-color: $background-color_3;
		background-color: $background-color_11;
		-webkit-border-radius: 1px;
		-moz-border-radius: 1px;
		border-radius: 1px;
	}
	.mCSB_dragger {
		.mCSB_dragger_bar {
			width: 4px;
			background-color: $background-color_3;
			background-color: $background-color_12;
			-webkit-border-radius: 1px;
			-moz-border-radius: 1px;
			border-radius: 1px;
		}
		&:hover {
			.mCSB_dragger_bar {
				background-color: $background-color_3;
				background-color: $background-color_13;
			}
		}
		&:active {
			.mCSB_dragger_bar {
				background-color: $background-color_3;
				background-color: $background-color_14;
			}
		}
	}
	.mCSB_dragger.mCSB_dragger_onDrag {
		.mCSB_dragger_bar {
			background-color: $background-color_3;
			background-color: $background-color_14;
		}
	}
	.mCSB_buttonUp {
		background-position: -32px 0;
	}
	.mCSB_buttonDown {
		background-position: -32px -20px;
	}
	.mCSB_buttonLeft {
		background-position: -40px -40px;
	}
	.mCSB_buttonRight {
		background-position: -40px -56px;
	}
}
.mCS-dark-2.mCSB_scrollTools {
	.mCSB_draggerRail {
		width: 4px;
		background-color: $background-color_3;
		background-color: $background-color_11;
		-webkit-border-radius: 1px;
		-moz-border-radius: 1px;
		border-radius: 1px;
		background-color: $background-color_1;
		background-color: $background-color_15;
		-webkit-border-radius: 1px;
		-moz-border-radius: 1px;
		border-radius: 1px;
	}
	.mCSB_dragger {
		.mCSB_dragger_bar {
			width: 4px;
			background-color: $background-color_3;
			background-color: $background-color_12;
			-webkit-border-radius: 1px;
			-moz-border-radius: 1px;
			border-radius: 1px;
			background-color: $background-color_1;
			background-color: $background-color_8;
			-webkit-border-radius: 1px;
			-moz-border-radius: 1px;
			border-radius: 1px;
		}
		&:hover {
			.mCSB_dragger_bar {
				background-color: $background-color_1;
				background-color: $background-color_9;
			}
		}
		&:active {
			.mCSB_dragger_bar {
				background-color: $background-color_1;
				background-color: $background-color_10;
			}
		}
	}
	.mCSB_dragger.mCSB_dragger_onDrag {
		.mCSB_dragger_bar {
			background-color: $background-color_1;
			background-color: $background-color_10;
		}
	}
	.mCSB_buttonUp {
		background-position: -112px 0;
	}
	.mCSB_buttonDown {
		background-position: -112px -20px;
	}
	.mCSB_buttonLeft {
		background-position: -120px -40px;
	}
	.mCSB_buttonRight {
		background-position: -120px -56px;
	}
}
.mCS-light-2.mCSB_scrollTools_horizontal {
	.mCSB_draggerRail {
		width: 100%;
		height: 4px;
		margin: 6px auto;
	}
	.mCSB_dragger {
		.mCSB_dragger_bar {
			width: 100%;
			height: 4px;
			margin: 6px auto;
		}
	}
}
.mCS-dark-2.mCSB_scrollTools_horizontal {
	.mCSB_draggerRail {
		width: 100%;
		height: 4px;
		margin: 6px auto;
	}
	.mCSB_dragger {
		.mCSB_dragger_bar {
			width: 100%;
			height: 4px;
			margin: 6px auto;
		}
	}
}
.mCS-light-thick.mCSB_scrollTools {
	.mCSB_draggerRail {
		width: 4px;
		background-color: $background-color_3;
		background-color: $background-color_11;
		-webkit-border-radius: 2px;
		-moz-border-radius: 2px;
		border-radius: 2px;
	}
	.mCSB_dragger {
		.mCSB_dragger_bar {
			width: 6px;
			background-color: $background-color_3;
			background-color: $background-color_12;
			-webkit-border-radius: 2px;
			-moz-border-radius: 2px;
			border-radius: 2px;
		}
		&:hover {
			.mCSB_dragger_bar {
				background-color: $background-color_3;
				background-color: $background-color_13;
			}
		}
		&:active {
			.mCSB_dragger_bar {
				background-color: $background-color_3;
				background-color: $background-color_14;
			}
		}
	}
	.mCSB_dragger.mCSB_dragger_onDrag {
		.mCSB_dragger_bar {
			background-color: $background-color_3;
			background-color: $background-color_14;
		}
	}
	.mCSB_buttonUp {
		background-position: -16px 0;
	}
	.mCSB_buttonDown {
		background-position: -16px -20px;
	}
	.mCSB_buttonLeft {
		background-position: -20px -40px;
	}
	.mCSB_buttonRight {
		background-position: -20px -56px;
	}
}
.mCS-dark-thick.mCSB_scrollTools {
	.mCSB_draggerRail {
		width: 4px;
		background-color: $background-color_3;
		background-color: $background-color_11;
		-webkit-border-radius: 2px;
		-moz-border-radius: 2px;
		border-radius: 2px;
		background-color: $background-color_1;
		background-color: $background-color_15;
		-webkit-border-radius: 2px;
		-moz-border-radius: 2px;
		border-radius: 2px;
	}
	.mCSB_dragger {
		.mCSB_dragger_bar {
			width: 6px;
			background-color: $background-color_3;
			background-color: $background-color_12;
			-webkit-border-radius: 2px;
			-moz-border-radius: 2px;
			border-radius: 2px;
			background-color: $background-color_1;
			background-color: $background-color_8;
			-webkit-border-radius: 2px;
			-moz-border-radius: 2px;
			border-radius: 2px;
		}
		&:hover {
			.mCSB_dragger_bar {
				background-color: $background-color_1;
				background-color: $background-color_9;
			}
		}
		&:active {
			.mCSB_dragger_bar {
				background-color: $background-color_1;
				background-color: $background-color_10;
			}
		}
	}
	.mCSB_dragger.mCSB_dragger_onDrag {
		.mCSB_dragger_bar {
			background-color: $background-color_1;
			background-color: $background-color_10;
		}
	}
	.mCSB_buttonUp {
		background-position: -96px 0;
	}
	.mCSB_buttonDown {
		background-position: -96px -20px;
	}
	.mCSB_buttonLeft {
		background-position: -100px -40px;
	}
	.mCSB_buttonRight {
		background-position: -100px -56px;
	}
}
.mCS-light-thick.mCSB_scrollTools_horizontal {
	.mCSB_draggerRail {
		width: 100%;
		height: 4px;
		margin: 6px 0;
	}
	.mCSB_dragger {
		.mCSB_dragger_bar {
			width: 100%;
			height: 6px;
			margin: 5px auto;
		}
	}
}
.mCS-dark-thick.mCSB_scrollTools_horizontal {
	.mCSB_draggerRail {
		width: 100%;
		height: 4px;
		margin: 6px 0;
	}
	.mCSB_dragger {
		.mCSB_dragger_bar {
			width: 100%;
			height: 6px;
			margin: 5px auto;
		}
	}
}
.mCS-light-thin.mCSB_scrollTools {
	.mCSB_draggerRail {
		background-color: $background-color_3;
		background-color: $background-color_11;
	}
	.mCSB_dragger {
		.mCSB_dragger_bar {
			width: 2px;
		}
	}
}
.mCS-dark-thin.mCSB_scrollTools {
	.mCSB_dragger {
		.mCSB_dragger_bar {
			width: 2px;
			background-color: $background-color_1;
			background-color: $background-color_8;
		}
		&:hover {
			.mCSB_dragger_bar {
				background-color: $background-color_1;
				background-color: $background-color_9;
			}
		}
		&:active {
			.mCSB_dragger_bar {
				background-color: $background-color_1;
				background-color: $background-color_10;
			}
		}
	}
	.mCSB_draggerRail {
		background-color: $background-color_1;
		background-color: $background-color_7;
	}
	.mCSB_dragger.mCSB_dragger_onDrag {
		.mCSB_dragger_bar {
			background-color: $background-color_1;
			background-color: $background-color_10;
		}
	}
	.mCSB_buttonUp {
		background-position: -80px 0;
	}
	.mCSB_buttonDown {
		background-position: -80px -20px;
	}
	.mCSB_buttonLeft {
		background-position: -80px -40px;
	}
	.mCSB_buttonRight {
		background-position: -80px -56px;
	}
}
.mCS-light-thin.mCSB_scrollTools_horizontal {
	.mCSB_draggerRail {
		width: 100%;
	}
	.mCSB_dragger {
		.mCSB_dragger_bar {
			width: 100%;
			height: 2px;
			margin: 7px auto;
		}
	}
}
.mCS-dark-thin.mCSB_scrollTools_horizontal {
	.mCSB_draggerRail {
		width: 100%;
	}
	.mCSB_dragger {
		.mCSB_dragger_bar {
			width: 100%;
			height: 2px;
			margin: 7px auto;
		}
	}
}
.mCS-rounded.mCSB_scrollTools {
	.mCSB_draggerRail {
		background-color: $background-color_3;
		background-color: $background-color_16;
	}
	.mCSB_dragger {
		height: 14px;
		.mCSB_dragger_bar {
			width: 14px;
			margin: 0 1px;
		}
	}
	.mCSB_buttonUp {
		background-position: 0 -72px;
	}
	.mCSB_buttonDown {
		background-position: 0 -92px;
	}
	.mCSB_buttonLeft {
		background-position: 0 -112px;
	}
	.mCSB_buttonRight {
		background-position: 0 -128px;
	}
}
.mCS-rounded-dark.mCSB_scrollTools {
	.mCSB_dragger {
		height: 14px;
		.mCSB_dragger_bar {
			width: 14px;
			margin: 0 1px;
			background-color: $background-color_1;
			background-color: $background-color_8;
		}
		&:hover {
			.mCSB_dragger_bar {
				background-color: $background-color_1;
				background-color: $background-color_9;
			}
		}
		&:active {
			.mCSB_dragger_bar {
				background-color: $background-color_1;
				background-color: $background-color_10;
			}
		}
	}
	.mCSB_draggerRail {
		background-color: $background-color_1;
		background-color: $background-color_7;
	}
	.mCSB_dragger.mCSB_dragger_onDrag {
		.mCSB_dragger_bar {
			background-color: $background-color_1;
			background-color: $background-color_10;
		}
	}
	.mCSB_buttonUp {
		background-position: -80px -72px;
	}
	.mCSB_buttonDown {
		background-position: -80px -92px;
	}
	.mCSB_buttonLeft {
		background-position: -80px -112px;
	}
	.mCSB_buttonRight {
		background-position: -80px -128px;
	}
}
.mCS-rounded-dots.mCSB_scrollTools {
	.mCSB_dragger {
		height: 14px;
		.mCSB_dragger_bar {
			width: 14px;
			margin: 0 1px;
		}
	}
	.mCSB_draggerRail {
		background-color: $background-color_17;
		background-position: center;
		background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAANElEQVQYV2NkIAAYiVbw//9/Y6DiM1ANJoyMjGdBbLgJQAX/kU0DKgDLkaQAvxW4HEvQFwCRcxIJK1XznAAAAABJRU5ErkJggg==");
		background-repeat: repeat-y;
		opacity: 0.3;
		filter: "alpha(opacity=30)";
		-ms-filter: "alpha(opacity=30)";
	}
	.mCSB_buttonUp {
		background-position: -16px -72px;
	}
	.mCSB_buttonDown {
		background-position: -16px -92px;
	}
	.mCSB_buttonLeft {
		background-position: -20px -112px;
	}
	.mCSB_buttonRight {
		background-position: -20px -128px;
	}
}
.mCS-rounded-dots-dark.mCSB_scrollTools {
	.mCSB_dragger {
		height: 14px;
		.mCSB_dragger_bar {
			width: 14px;
			margin: 0 1px;
			background-color: $background-color_1;
			background-color: $background-color_8;
		}
		&:hover {
			.mCSB_dragger_bar {
				background-color: $background-color_1;
				background-color: $background-color_9;
			}
		}
		&:active {
			.mCSB_dragger_bar {
				background-color: $background-color_1;
				background-color: $background-color_10;
			}
		}
	}
	.mCSB_dragger.mCSB_dragger_onDrag {
		.mCSB_dragger_bar {
			background-color: $background-color_1;
			background-color: $background-color_10;
		}
	}
	.mCSB_draggerRail {
		background-color: $background-color_17;
		background-position: center;
		background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAANElEQVQYV2NkIAAYiVbw//9/Y6DiM1ANJoyMjGdBbLgJQAX/kU0DKgDLkaQAvxW4HEvQFwCRcxIJK1XznAAAAABJRU5ErkJggg==");
		background-repeat: repeat-y;
		opacity: 0.3;
		filter: "alpha(opacity=30)";
		-ms-filter: "alpha(opacity=30)";
		background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAALElEQVQYV2NkIAAYSVFgDFR8BqrBBEifBbGRTfiPZhpYjiQFBK3A6l6CvgAAE9kGCd1mvgEAAAAASUVORK5CYII=");
	}
	.mCSB_buttonUp {
		background-position: -96px -72px;
	}
	.mCSB_buttonDown {
		background-position: -96px -92px;
	}
	.mCSB_buttonLeft {
		background-position: -100px -112px;
	}
	.mCSB_buttonRight {
		background-position: -100px -128px;
	}
}
.mCS-rounded.mCSB_scrollTools_horizontal {
	.mCSB_dragger {
		width: 14px;
		.mCSB_dragger_bar {
			height: 14px;
			margin: 1px 0;
		}
	}
}
.mCS-rounded-dark.mCSB_scrollTools_horizontal {
	.mCSB_dragger {
		width: 14px;
		.mCSB_dragger_bar {
			height: 14px;
			margin: 1px 0;
		}
	}
}
.mCS-rounded-dots.mCSB_scrollTools_horizontal {
	.mCSB_dragger {
		width: 14px;
		.mCSB_dragger_bar {
			height: 14px;
			margin: 1px 0;
		}
	}
	.mCSB_draggerRail {
		background-color: $background-color_17;
		background-position: center;
		height: 4px;
		margin: 6px 0;
		background-repeat: repeat-x;
	}
}
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal {
	.mCSB_dragger {
		width: 14px;
		.mCSB_dragger_bar {
			height: 14px;
			margin: 1px 0;
		}
	}
	.mCSB_draggerRail {
		background-color: $background-color_17;
		background-position: center;
		height: 4px;
		margin: 6px 0;
		background-repeat: repeat-x;
	}
}
.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand {
	.mCSB_dragger.mCSB_dragger_onDrag_expanded {
		.mCSB_dragger_bar {
			width: 16px;
			height: 16px;
			margin: -1px 0;
		}
		+ {
			.mCSB_draggerRail {
				width: 4px;
			}
		}
	}
	.mCSB_draggerContainer {
		&:hover {
			.mCSB_dragger {
				.mCSB_dragger_bar {
					width: 16px;
					height: 16px;
					margin: -1px 0;
				}
			}
			.mCSB_draggerRail {
				width: 4px;
			}
		}
	}
}
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand {
	.mCSB_dragger.mCSB_dragger_onDrag_expanded {
		.mCSB_dragger_bar {
			width: 16px;
			height: 16px;
			margin: -1px 0;
		}
		+ {
			.mCSB_draggerRail {
				width: 4px;
			}
		}
	}
	.mCSB_draggerContainer {
		&:hover {
			.mCSB_dragger {
				.mCSB_dragger_bar {
					width: 16px;
					height: 16px;
					margin: -1px 0;
				}
			}
			.mCSB_draggerRail {
				width: 4px;
			}
		}
	}
}
.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand {
	.mCSB_dragger.mCSB_dragger_onDrag_expanded {
		.mCSB_dragger_bar {
			height: 16px;
			width: 16px;
			margin: 0 -1px;
		}
		+ {
			.mCSB_draggerRail {
				height: 4px;
				margin: 6px 0;
			}
		}
	}
	.mCSB_draggerContainer {
		&:hover {
			.mCSB_dragger {
				.mCSB_dragger_bar {
					height: 16px;
					width: 16px;
					margin: 0 -1px;
				}
			}
			.mCSB_draggerRail {
				height: 4px;
				margin: 6px 0;
			}
		}
	}
}
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand {
	.mCSB_dragger.mCSB_dragger_onDrag_expanded {
		.mCSB_dragger_bar {
			height: 16px;
			width: 16px;
			margin: 0 -1px;
		}
		+ {
			.mCSB_draggerRail {
				height: 4px;
				margin: 6px 0;
			}
		}
	}
	.mCSB_draggerContainer {
		&:hover {
			.mCSB_dragger {
				.mCSB_dragger_bar {
					height: 16px;
					width: 16px;
					margin: 0 -1px;
				}
			}
			.mCSB_draggerRail {
				height: 4px;
				margin: 6px 0;
			}
		}
	}
}
.mCS-rounded-dots.mCSB_scrollTools_vertical {
	.mCSB_draggerRail {
		width: 4px;
	}
}
.mCS-rounded-dots-dark.mCSB_scrollTools_vertical {
	.mCSB_draggerRail {
		width: 4px;
	}
}
.mCS-3d.mCSB_scrollTools {
	.mCSB_dragger {
		.mCSB_dragger_bar {
			background-repeat: repeat-y;
			background-image: -moz-linear-gradient(left, rgba(255,255,255,0.5) 0%, rgba(255,255,255,0) 100%);
			background-image: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(255,255,255,0.5)), color-stop(100%,rgba(255,255,255,0)));
			background-image: -webkit-linear-gradient(left, rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 100%);
			background-image: -o-linear-gradient(left, rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 100%);
			background-image: -ms-linear-gradient(left, rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 100%);
			background-image: linear-gradient(to right, rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 100%);
			-webkit-border-radius: 16px;
			-moz-border-radius: 16px;
			border-radius: 16px;
			background-color: $background-color_19;
			width: 8px;
		}
		&:hover {
			.mCSB_dragger_bar {
				background-color: $background-color_19;
			}
		}
		&:active {
			.mCSB_dragger_bar {
				background-color: $background-color_19;
			}
		}
	}
	opacity: 1;
	filter: "alpha(opacity=30)";
	-ms-filter: "alpha(opacity=30)";
	.mCSB_draggerRail {
		-webkit-border-radius: 16px;
		-moz-border-radius: 16px;
		border-radius: 16px;
		width: 8px;
		background-color: $background-color_1;
		background-color: $background-color_18;
		box-shadow: inset 1px 0 1px rgba(0,0,0,0.5), inset -1px 0 1px rgba(255,255,255,0.2);
	}
	.mCSB_dragger.mCSB_dragger_onDrag {
		.mCSB_dragger_bar {
			background-color: $background-color_19;
		}
	}
	.mCSB_buttonUp {
		background-position: -32px -72px;
	}
	.mCSB_buttonDown {
		background-position: -32px -92px;
	}
	.mCSB_buttonLeft {
		background-position: -40px -112px;
	}
	.mCSB_buttonRight {
		background-position: -40px -128px;
	}
}
.mCS-3d-dark.mCSB_scrollTools {
	.mCSB_dragger {
		.mCSB_dragger_bar {
			background-repeat: repeat-y;
			background-image: -moz-linear-gradient(left, rgba(255,255,255,0.5) 0%, rgba(255,255,255,0) 100%);
			background-image: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(255,255,255,0.5)), color-stop(100%,rgba(255,255,255,0)));
			background-image: -webkit-linear-gradient(left, rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 100%);
			background-image: -o-linear-gradient(left, rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 100%);
			background-image: -ms-linear-gradient(left, rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 100%);
			background-image: linear-gradient(to right, rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 100%);
			-webkit-border-radius: 16px;
			-moz-border-radius: 16px;
			border-radius: 16px;
			background-color: $background-color_19;
			width: 8px;
		}
		&:hover {
			.mCSB_dragger_bar {
				background-color: $background-color_19;
			}
		}
		&:active {
			.mCSB_dragger_bar {
				background-color: $background-color_19;
			}
		}
	}
	opacity: 1;
	filter: "alpha(opacity=30)";
	-ms-filter: "alpha(opacity=30)";
	.mCSB_draggerRail {
		-webkit-border-radius: 16px;
		-moz-border-radius: 16px;
		border-radius: 16px;
		width: 8px;
		background-color: $background-color_1;
		background-color: $background-color_18;
		box-shadow: inset 1px 0 1px rgba(0,0,0,0.5), inset -1px 0 1px rgba(255,255,255,0.2);
		background-color: $background-color_1;
		background-color: $background-color_15;
		box-shadow: inset 1px 0 1px rgba(0,0,0,0.1);
	}
	.mCSB_dragger.mCSB_dragger_onDrag {
		.mCSB_dragger_bar {
			background-color: $background-color_19;
		}
	}
	.mCSB_buttonUp {
		background-position: -112px -72px;
	}
	.mCSB_buttonDown {
		background-position: -112px -92px;
	}
	.mCSB_buttonLeft {
		background-position: -120px -112px;
	}
	.mCSB_buttonRight {
		background-position: -120px -128px;
	}
}
.mCS-3d-thick.mCSB_scrollTools {
	.mCSB_dragger {
		.mCSB_dragger_bar {
			background-repeat: repeat-y;
			background-image: -moz-linear-gradient(left, rgba(255,255,255,0.5) 0%, rgba(255,255,255,0) 100%);
			background-image: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(255,255,255,0.5)), color-stop(100%,rgba(255,255,255,0)));
			background-image: -webkit-linear-gradient(left, rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 100%);
			background-image: -o-linear-gradient(left, rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 100%);
			background-image: -ms-linear-gradient(left, rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 100%);
			background-image: linear-gradient(to right, rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 100%);
			-webkit-border-radius: 5px;
			-moz-border-radius: 5px;
			border-radius: 5px;
			box-shadow: inset 1px 0 0 rgba(255,255,255,0.4);
			width: 12px;
			margin: 2px;
			position: absolute;
			height: auto;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background-color: $background-color_19;
		}
		&:hover {
			.mCSB_dragger_bar {
				background-color: $background-color_19;
			}
		}
		&:active {
			.mCSB_dragger_bar {
				background-color: $background-color_19;
			}
		}
	}
	opacity: 1;
	filter: "alpha(opacity=30)";
	-ms-filter: "alpha(opacity=30)";
	-webkit-border-radius: 7px;
	-moz-border-radius: 7px;
	border-radius: 7px;
	.mCSB_draggerContainer {
		-webkit-border-radius: 7px;
		-moz-border-radius: 7px;
		border-radius: 7px;
		background-color: $background-color_1;
		background-color: $background-color_20;
		box-shadow: inset 1px 1px 16px rgba(0,0,0,0.1);
	}
	.mCSB_dragger.mCSB_dragger_onDrag {
		.mCSB_dragger_bar {
			background-color: $background-color_19;
		}
	}
	.mCSB_draggerRail {
		background-color: $background-color_17;
	}
	.mCSB_buttonUp {
		background-position: -32px -72px;
	}
	.mCSB_buttonDown {
		background-position: -32px -92px;
	}
	.mCSB_buttonLeft {
		background-position: -40px -112px;
	}
	.mCSB_buttonRight {
		background-position: -40px -128px;
	}
}
.mCS-3d-thick-dark.mCSB_scrollTools {
	.mCSB_dragger {
		.mCSB_dragger_bar {
			background-repeat: repeat-y;
			background-image: -moz-linear-gradient(left, rgba(255,255,255,0.5) 0%, rgba(255,255,255,0) 100%);
			background-image: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(255,255,255,0.5)), color-stop(100%,rgba(255,255,255,0)));
			background-image: -webkit-linear-gradient(left, rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 100%);
			background-image: -o-linear-gradient(left, rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 100%);
			background-image: -ms-linear-gradient(left, rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 100%);
			background-image: linear-gradient(to right, rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 100%);
			-webkit-border-radius: 5px;
			-moz-border-radius: 5px;
			border-radius: 5px;
			box-shadow: inset 1px 0 0 rgba(255,255,255,0.4);
			width: 12px;
			margin: 2px;
			position: absolute;
			height: auto;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			box-shadow: inset 1px 0 0 rgba(255,255,255,0.4), inset -1px 0 0 rgba(0,0,0,0.2);
			background-color: $background-color_21;
		}
		&:hover {
			.mCSB_dragger_bar {
				background-color: $background-color_21;
			}
		}
		&:active {
			.mCSB_dragger_bar {
				background-color: $background-color_21;
			}
		}
	}
	opacity: 1;
	filter: "alpha(opacity=30)";
	-ms-filter: "alpha(opacity=30)";
	-webkit-border-radius: 7px;
	-moz-border-radius: 7px;
	border-radius: 7px;
	box-shadow: inset 0 0 14px rgba(0,0,0,0.2);
	.mCSB_draggerContainer {
		-webkit-border-radius: 7px;
		-moz-border-radius: 7px;
		border-radius: 7px;
		background-color: $background-color_3;
		background-color: $background-color_20;
		box-shadow: inset 1px 1px 16px rgba(0,0,0,0.1);
	}
	.mCSB_dragger.mCSB_dragger_onDrag {
		.mCSB_dragger_bar {
			background-color: $background-color_21;
		}
	}
	.mCSB_draggerRail {
		background-color: $background-color_17;
	}
	.mCSB_buttonUp {
		background-position: -112px -72px;
	}
	.mCSB_buttonDown {
		background-position: -112px -92px;
	}
	.mCSB_buttonLeft {
		background-position: -120px -112px;
	}
	.mCSB_buttonRight {
		background-position: -120px -128px;
	}
}
.mCS-3d.mCSB_scrollTools_horizontal {
	.mCSB_dragger {
		.mCSB_dragger_bar {
			background-repeat: repeat-x;
			background-image: -moz-linear-gradient(top, rgba(255,255,255,0.5) 0%, rgba(255,255,255,0) 100%);
			background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,255,255,0.5)), color-stop(100%,rgba(255,255,255,0)));
			background-image: -webkit-linear-gradient(top, rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 100%);
			background-image: -o-linear-gradient(top, rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 100%);
			background-image: -ms-linear-gradient(top, rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 100%);
			background-image: linear-gradient(to bottom, rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 100%);
			width: 100%;
			height: 8px;
			margin: 4px auto;
		}
		width: 70px;
	}
	.mCSB_draggerRail {
		width: 100%;
		height: 8px;
		margin: 4px 0;
		box-shadow: inset 0 1px 1px rgba(0,0,0,0.5), inset 0 -1px 1px rgba(255,255,255,0.2);
	}
}
.mCS-3d-dark.mCSB_scrollTools_horizontal {
	.mCSB_dragger {
		.mCSB_dragger_bar {
			background-repeat: repeat-x;
			background-image: -moz-linear-gradient(top, rgba(255,255,255,0.5) 0%, rgba(255,255,255,0) 100%);
			background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,255,255,0.5)), color-stop(100%,rgba(255,255,255,0)));
			background-image: -webkit-linear-gradient(top, rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 100%);
			background-image: -o-linear-gradient(top, rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 100%);
			background-image: -ms-linear-gradient(top, rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 100%);
			background-image: linear-gradient(to bottom, rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 100%);
			width: 100%;
			height: 8px;
			margin: 4px auto;
		}
		width: 70px;
	}
	.mCSB_draggerRail {
		width: 100%;
		height: 8px;
		margin: 4px 0;
		box-shadow: inset 0 1px 1px rgba(0,0,0,0.5), inset 0 -1px 1px rgba(255,255,255,0.2);
		box-shadow: inset 0 1px 1px rgba(0,0,0,0.1);
	}
}
.mCS-3d-thick.mCSB_scrollTools_horizontal {
	.mCSB_dragger {
		.mCSB_dragger_bar {
			background-repeat: repeat-x;
			background-image: -moz-linear-gradient(top, rgba(255,255,255,0.5) 0%, rgba(255,255,255,0) 100%);
			background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,255,255,0.5)), color-stop(100%,rgba(255,255,255,0)));
			background-image: -webkit-linear-gradient(top, rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 100%);
			background-image: -o-linear-gradient(top, rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 100%);
			background-image: -ms-linear-gradient(top, rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 100%);
			background-image: linear-gradient(to bottom, rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 100%);
			box-shadow: inset 0 1px 0 rgba(255,255,255,0.4);
			height: 12px;
			width: auto;
		}
	}
	bottom: 1px;
	box-shadow: inset 0 1px 1px rgba(0,0,0,0.1), inset 0 0 14px rgba(0,0,0,0.5);
}
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal {
	.mCSB_dragger {
		.mCSB_dragger_bar {
			background-repeat: repeat-x;
			background-image: -moz-linear-gradient(top, rgba(255,255,255,0.5) 0%, rgba(255,255,255,0) 100%);
			background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,255,255,0.5)), color-stop(100%,rgba(255,255,255,0)));
			background-image: -webkit-linear-gradient(top, rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 100%);
			background-image: -o-linear-gradient(top, rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 100%);
			background-image: -ms-linear-gradient(top, rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 100%);
			background-image: linear-gradient(to bottom, rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 100%);
			box-shadow: inset 0 1px 0 rgba(255,255,255,0.4);
			height: 12px;
			width: auto;
			box-shadow: inset 0 1px 0 rgba(255,255,255,0.4), inset 0 -1px 0 rgba(0,0,0,0.2);
		}
	}
	bottom: 1px;
	box-shadow: inset 0 1px 1px rgba(0,0,0,0.1), inset 0 0 14px rgba(0,0,0,0.5);
	box-shadow: inset 0 1px 1px rgba(0,0,0,0.1), inset 0 0 14px rgba(0,0,0,0.2);
}
.mCS-3d.mCSB_scrollTools_vertical {
	.mCSB_dragger {
		height: 70px;
	}
}
.mCS-3d-dark.mCSB_scrollTools_vertical {
	.mCSB_dragger {
		height: 70px;
	}
}
.mCS-3d-thick.mCSB_scrollTools_vertical {
	box-shadow: inset 1px 0 1px rgba(0,0,0,0.1), inset 0 0 14px rgba(0,0,0,0.5);
}
.mCS-3d-thick-dark.mCSB_scrollTools_vertical {
	box-shadow: inset 1px 0 1px rgba(0,0,0,0.1), inset 0 0 14px rgba(0,0,0,0.5);
}
.mCustomScrollBox.mCS-minimal {
	+ {
		.mCSB_scrollTools.mCSB_scrollTools_horizontal {
			bottom: 0;
			margin: 0 12px;
		}
		.mCSB_scrollTools {
			+ {
				.mCSB_scrollTools.mCSB_scrollTools_horizontal {
					bottom: 0;
					margin: 0 12px;
				}
			}
		}
	}
}
.mCustomScrollBox.mCS-minimal-dark {
	+ {
		.mCSB_scrollTools.mCSB_scrollTools_horizontal {
			bottom: 0;
			margin: 0 12px;
		}
		.mCSB_scrollTools {
			+ {
				.mCSB_scrollTools.mCSB_scrollTools_horizontal {
					bottom: 0;
					margin: 0 12px;
				}
			}
		}
	}
}
.mCS-minimal.mCSB_scrollTools {
	.mCSB_draggerRail {
		background-color: $background-color_17;
	}
	.mCSB_dragger {
		.mCSB_dragger_bar {
			background-color: $background-color_3;
			background-color: $background-color_22;
			filter: "alpha(opacity=20)";
			-ms-filter: "alpha(opacity=20)";
		}
		&:active {
			.mCSB_dragger_bar {
				background-color: $background-color_3;
				background-color: $background-color_23;
				filter: "alpha(opacity=50)";
				-ms-filter: "alpha(opacity=50)";
			}
		}
	}
	.mCSB_dragger.mCSB_dragger_onDrag {
		.mCSB_dragger_bar {
			background-color: $background-color_3;
			background-color: $background-color_23;
			filter: "alpha(opacity=50)";
			-ms-filter: "alpha(opacity=50)";
		}
	}
}
.mCS-minimal-dark.mCSB_scrollTools {
	.mCSB_draggerRail {
		background-color: $background-color_17;
	}
	.mCSB_dragger {
		.mCSB_dragger_bar {
			background-color: $background-color_1;
			background-color: $background-color_18;
			filter: "alpha(opacity=20)";
			-ms-filter: "alpha(opacity=20)";
		}
		&:active {
			.mCSB_dragger_bar {
				background-color: $background-color_1;
				background-color: $background-color_24;
				filter: "alpha(opacity=50)";
				-ms-filter: "alpha(opacity=50)";
			}
		}
	}
	.mCSB_dragger.mCSB_dragger_onDrag {
		.mCSB_dragger_bar {
			background-color: $background-color_1;
			background-color: $background-color_24;
			filter: "alpha(opacity=50)";
			-ms-filter: "alpha(opacity=50)";
		}
	}
}
.mCS-minimal.mCSB_scrollTools_vertical {
	.mCSB_dragger {
		height: 50px;
	}
}
.mCS-minimal-dark.mCSB_scrollTools_vertical {
	.mCSB_dragger {
		height: 50px;
	}
}
.mCS-minimal.mCSB_scrollTools_horizontal {
	.mCSB_dragger {
		width: 50px;
	}
}
.mCS-minimal-dark.mCSB_scrollTools_horizontal {
	.mCSB_dragger {
		width: 50px;
	}
}
.mCS-light-3.mCSB_scrollTools {
	.mCSB_draggerRail {
		width: 6px;
		background-color: $background-color_1;
		background-color: $background-color_18;
	}
	.mCSB_dragger {
		.mCSB_dragger_bar {
			width: 6px;
		}
	}
	.mCSB_buttonUp {
		background-position: -32px -72px;
	}
	.mCSB_buttonDown {
		background-position: -32px -92px;
	}
	.mCSB_buttonLeft {
		background-position: -40px -112px;
	}
	.mCSB_buttonRight {
		background-position: -40px -128px;
	}
}
.mCS-dark-3.mCSB_scrollTools {
	.mCSB_draggerRail {
		width: 6px;
		background-color: $background-color_1;
		background-color: $background-color_18;
		background-color: $background-color_1;
		background-color: $background-color_15;
	}
	.mCSB_dragger {
		.mCSB_dragger_bar {
			width: 6px;
			background-color: $background-color_1;
			background-color: $background-color_8;
		}
		&:hover {
			.mCSB_dragger_bar {
				background-color: $background-color_1;
				background-color: $background-color_9;
			}
		}
		&:active {
			.mCSB_dragger_bar {
				background-color: $background-color_1;
				background-color: $background-color_10;
			}
		}
	}
	.mCSB_dragger.mCSB_dragger_onDrag {
		.mCSB_dragger_bar {
			background-color: $background-color_1;
			background-color: $background-color_10;
		}
	}
	.mCSB_buttonUp {
		background-position: -112px -72px;
	}
	.mCSB_buttonDown {
		background-position: -112px -92px;
	}
	.mCSB_buttonLeft {
		background-position: -120px -112px;
	}
	.mCSB_buttonRight {
		background-position: -120px -128px;
	}
}
.mCS-light-3.mCSB_scrollTools_horizontal {
	.mCSB_dragger {
		.mCSB_dragger_bar {
			width: 100%;
			height: 6px;
			margin: 5px 0;
		}
	}
	.mCSB_draggerRail {
		width: 100%;
		height: 6px;
		margin: 5px 0;
	}
}
.mCS-dark-3.mCSB_scrollTools_horizontal {
	.mCSB_dragger {
		.mCSB_dragger_bar {
			width: 100%;
			height: 6px;
			margin: 5px 0;
		}
	}
	.mCSB_draggerRail {
		width: 100%;
		height: 6px;
		margin: 5px 0;
	}
}
.mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand {
	.mCSB_dragger.mCSB_dragger_onDrag_expanded {
		+ {
			.mCSB_draggerRail {
				width: 12px;
			}
		}
	}
	.mCSB_draggerContainer {
		&:hover {
			.mCSB_draggerRail {
				width: 12px;
			}
		}
	}
}
.mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand {
	.mCSB_dragger.mCSB_dragger_onDrag_expanded {
		+ {
			.mCSB_draggerRail {
				width: 12px;
			}
		}
	}
	.mCSB_draggerContainer {
		&:hover {
			.mCSB_draggerRail {
				width: 12px;
			}
		}
	}
}
.mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand {
	.mCSB_dragger.mCSB_dragger_onDrag_expanded {
		+ {
			.mCSB_draggerRail {
				height: 12px;
				margin: 2px 0;
			}
		}
	}
	.mCSB_draggerContainer {
		&:hover {
			.mCSB_draggerRail {
				height: 12px;
				margin: 2px 0;
			}
		}
	}
}
.mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand {
	.mCSB_dragger.mCSB_dragger_onDrag_expanded {
		+ {
			.mCSB_draggerRail {
				height: 12px;
				margin: 2px 0;
			}
		}
	}
	.mCSB_draggerContainer {
		&:hover {
			.mCSB_draggerRail {
				height: 12px;
				margin: 2px 0;
			}
		}
	}
}
.mCS-inset.mCSB_scrollTools {
	.mCSB_draggerRail {
		width: 12px;
		background-color: $background-color_1;
		background-color: $background-color_18;
	}
	.mCSB_dragger {
		.mCSB_dragger_bar {
			width: 6px;
			margin: 3px 5px;
			position: absolute;
			height: auto;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
		}
	}
	.mCSB_buttonUp {
		background-position: -32px -72px;
	}
	.mCSB_buttonDown {
		background-position: -32px -92px;
	}
	.mCSB_buttonLeft {
		background-position: -40px -112px;
	}
	.mCSB_buttonRight {
		background-position: -40px -128px;
	}
}
.mCS-inset-dark.mCSB_scrollTools {
	.mCSB_draggerRail {
		width: 12px;
		background-color: $background-color_1;
		background-color: $background-color_18;
		background-color: $background-color_1;
		background-color: $background-color_15;
	}
	.mCSB_dragger {
		.mCSB_dragger_bar {
			width: 6px;
			margin: 3px 5px;
			position: absolute;
			height: auto;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background-color: $background-color_1;
			background-color: $background-color_8;
		}
		&:hover {
			.mCSB_dragger_bar {
				background-color: $background-color_1;
				background-color: $background-color_9;
			}
		}
		&:active {
			.mCSB_dragger_bar {
				background-color: $background-color_1;
				background-color: $background-color_10;
			}
		}
	}
	.mCSB_dragger.mCSB_dragger_onDrag {
		.mCSB_dragger_bar {
			background-color: $background-color_1;
			background-color: $background-color_10;
		}
	}
	.mCSB_buttonUp {
		background-position: -112px -72px;
	}
	.mCSB_buttonDown {
		background-position: -112px -92px;
	}
	.mCSB_buttonLeft {
		background-position: -120px -112px;
	}
	.mCSB_buttonRight {
		background-position: -120px -128px;
	}
}
.mCS-inset-2.mCSB_scrollTools {
	.mCSB_draggerRail {
		width: 12px;
		background-color: $background-color_1;
		background-color: $background-color_18;
		background-color: $background-color_17;
		border-width: 1px;
		border-style: solid;
		border-color: $border-color_1;
		border-color: $border-color_2;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
	}
	.mCSB_dragger {
		.mCSB_dragger_bar {
			width: 6px;
			margin: 3px 5px;
			position: absolute;
			height: auto;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
		}
	}
	.mCSB_buttonUp {
		background-position: -32px -72px;
	}
	.mCSB_buttonDown {
		background-position: -32px -92px;
	}
	.mCSB_buttonLeft {
		background-position: -40px -112px;
	}
	.mCSB_buttonRight {
		background-position: -40px -128px;
	}
}
.mCS-inset-2-dark.mCSB_scrollTools {
	.mCSB_draggerRail {
		width: 12px;
		background-color: $background-color_1;
		background-color: $background-color_18;
		background-color: $background-color_1;
		background-color: $background-color_15;
		background-color: $background-color_17;
		border-width: 1px;
		border-style: solid;
		border-color: $border-color_1;
		border-color: $border-color_2;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		border-color: $border-color_3;
		border-color: $border-color_4;
	}
	.mCSB_dragger {
		.mCSB_dragger_bar {
			width: 6px;
			margin: 3px 5px;
			position: absolute;
			height: auto;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background-color: $background-color_1;
			background-color: $background-color_8;
		}
		&:hover {
			.mCSB_dragger_bar {
				background-color: $background-color_1;
				background-color: $background-color_9;
			}
		}
		&:active {
			.mCSB_dragger_bar {
				background-color: $background-color_1;
				background-color: $background-color_10;
			}
		}
	}
	.mCSB_dragger.mCSB_dragger_onDrag {
		.mCSB_dragger_bar {
			background-color: $background-color_1;
			background-color: $background-color_10;
		}
	}
	.mCSB_buttonUp {
		background-position: -112px -72px;
	}
	.mCSB_buttonDown {
		background-position: -112px -92px;
	}
	.mCSB_buttonLeft {
		background-position: -120px -112px;
	}
	.mCSB_buttonRight {
		background-position: -120px -128px;
	}
}
.mCS-inset-3.mCSB_scrollTools {
	.mCSB_draggerRail {
		width: 12px;
		background-color: $background-color_1;
		background-color: $background-color_18;
		background-color: $background-color_3;
		background-color: $background-color_25;
	}
	.mCSB_dragger {
		.mCSB_dragger_bar {
			width: 6px;
			margin: 3px 5px;
			position: absolute;
			height: auto;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background-color: $background-color_1;
			background-color: $background-color_8;
		}
		&:hover {
			.mCSB_dragger_bar {
				background-color: $background-color_1;
				background-color: $background-color_9;
			}
		}
		&:active {
			.mCSB_dragger_bar {
				background-color: $background-color_1;
				background-color: $background-color_10;
			}
		}
	}
	.mCSB_buttonUp {
		background-position: -32px -72px;
	}
	.mCSB_buttonDown {
		background-position: -32px -92px;
	}
	.mCSB_buttonLeft {
		background-position: -40px -112px;
	}
	.mCSB_buttonRight {
		background-position: -40px -128px;
	}
	.mCSB_dragger.mCSB_dragger_onDrag {
		.mCSB_dragger_bar {
			background-color: $background-color_1;
			background-color: $background-color_10;
		}
	}
}
.mCS-inset-3-dark.mCSB_scrollTools {
	.mCSB_draggerRail {
		width: 12px;
		background-color: $background-color_1;
		background-color: $background-color_18;
		background-color: $background-color_1;
		background-color: $background-color_15;
		background-color: $background-color_1;
		background-color: $background-color_26;
	}
	.mCSB_dragger {
		.mCSB_dragger_bar {
			width: 6px;
			margin: 3px 5px;
			position: absolute;
			height: auto;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background-color: $background-color_1;
			background-color: $background-color_8;
			background-color: $background-color_3;
			background-color: $background-color_12;
		}
		&:hover {
			.mCSB_dragger_bar {
				background-color: $background-color_1;
				background-color: $background-color_9;
				background-color: $background-color_3;
				background-color: $background-color_13;
			}
		}
		&:active {
			.mCSB_dragger_bar {
				background-color: $background-color_1;
				background-color: $background-color_10;
				background-color: $background-color_3;
				background-color: $background-color_14;
			}
		}
	}
	.mCSB_dragger.mCSB_dragger_onDrag {
		.mCSB_dragger_bar {
			background-color: $background-color_1;
			background-color: $background-color_10;
			background-color: $background-color_3;
			background-color: $background-color_14;
		}
	}
	.mCSB_buttonUp {
		background-position: -112px -72px;
	}
	.mCSB_buttonDown {
		background-position: -112px -92px;
	}
	.mCSB_buttonLeft {
		background-position: -120px -112px;
	}
	.mCSB_buttonRight {
		background-position: -120px -128px;
	}
}
.mCS-inset.mCSB_scrollTools_horizontal {
	.mCSB_dragger {
		.mCSB_dragger_bar {
			height: 6px;
			margin: 5px 3px;
			position: absolute;
			width: auto;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
		}
	}
	.mCSB_draggerRail {
		width: 100%;
		height: 12px;
		margin: 2px 0;
	}
}
.mCS-inset-dark.mCSB_scrollTools_horizontal {
	.mCSB_dragger {
		.mCSB_dragger_bar {
			height: 6px;
			margin: 5px 3px;
			position: absolute;
			width: auto;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
		}
	}
	.mCSB_draggerRail {
		width: 100%;
		height: 12px;
		margin: 2px 0;
	}
}
.mCS-inset-2.mCSB_scrollTools_horizontal {
	.mCSB_dragger {
		.mCSB_dragger_bar {
			height: 6px;
			margin: 5px 3px;
			position: absolute;
			width: auto;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
		}
	}
	.mCSB_draggerRail {
		width: 100%;
		height: 12px;
		margin: 2px 0;
	}
}
.mCS-inset-2-dark.mCSB_scrollTools_horizontal {
	.mCSB_dragger {
		.mCSB_dragger_bar {
			height: 6px;
			margin: 5px 3px;
			position: absolute;
			width: auto;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
		}
	}
	.mCSB_draggerRail {
		width: 100%;
		height: 12px;
		margin: 2px 0;
	}
}
.mCS-inset-3.mCSB_scrollTools_horizontal {
	.mCSB_dragger {
		.mCSB_dragger_bar {
			height: 6px;
			margin: 5px 3px;
			position: absolute;
			width: auto;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
		}
	}
	.mCSB_draggerRail {
		width: 100%;
		height: 12px;
		margin: 2px 0;
	}
}
.mCS-inset-3-dark.mCSB_scrollTools_horizontal {
	.mCSB_dragger {
		.mCSB_dragger_bar {
			height: 6px;
			margin: 5px 3px;
			position: absolute;
			width: auto;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
		}
	}
	.mCSB_draggerRail {
		width: 100%;
		height: 12px;
		margin: 2px 0;
	}
}
