section.discovery-section {
  h4 {
    margin-bottom: 1rem;
  }

  .discovery-modes {
    input[type="checkbox"],
    input[type="radio"] {
      margin-right: 0.5rem;
    }

    ul {
      // border: 5px solid yellow;
      --bs-list-group-color: var(--bs-body-color);
      --bs-list-group-item-padding-x: 1rem;
      --bs-list-group-item-padding-y: 0.5rem;

      li::before {
        // Increments only this instance of the section counter
        content: counters(section, ".") ". ";
        counter-increment: section;
      }

      li {
        position: relative;
        display: block;
        padding: var(--bs-list-group-item-padding-y)
          var(--bs-list-group-item-padding-x);
        color: var(--bs-list-group-color);
        // text-decoration: if($link-decoration == none, null, none);
        background-color: var(--bs-list-group-bg);
        border: var(--bs-list-group-border-width) solid
          var(--bs-list-group-border-color);

        &:first-child {
          border-top-left-radius: inherit;
          border-top-right-radius: inherit;
        }

        &:last-child {
          border-bottom-right-radius: inherit;
          border-bottom-left-radius: inherit;
        }

        &.disabled,
        &:disabled {
          color: var(--bs-list-group-disabled-color);
          pointer-events: none;
          background-color: var(--bs-list-group-disabled-bg);
        }

        // Include both here for `<a>`s and `<button>`s
        &.active {
          z-index: 2; // Place active items above their siblings for proper border styling
          color: var(--bs-list-group-active-color);
          background-color: var(--bs-list-group-active-bg);
          border-color: var(--bs-list-group-active-border-color);
        }

        // stylelint-disable-next-line scss/selector-no-redundant-nesting-selector
        & + .list-group-item {
          border-top-width: 0;

          &.active {
            margin-top: calc(
              -1 * var(--bs-list-group-border-width)
            ); // stylelint-disable-line function-disallowed-list
            border-top-width: var(--bs-list-group-border-width);
          }
        }
      }
    }
  }
}
