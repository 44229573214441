@import "forms/labels";
@import "forms/form-text";
@import "forms/form-control";
@import "forms/form-select";
@import "forms/form-check";
@import "forms/form-range";
@import "forms/floating-labels";
@import "forms/input-group";
@import "forms/validation";


.form-control {
  &.ng-touched{
    &.ng-invalid {
      border-color: var(--bs-danger);// #dc3545;
    }
    &.ng-valid {
      border-color: var(--bs-success);
    }
  }
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

input[type=text],
input[type=submit],
input[type=password],
input[type=datetime],
input[type=datetime-local],
input[type=date],
input[type=month],
input[type=time],
input[type=week],
input[type=number],
input[type=email],
input[type=url],
input[type=search],
input[type=tel],
input[type=color],
input:disabled {
  color: var(--bs-body-color)
}
