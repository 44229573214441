
/* Box
-------------------------------------------------------------- */

.box {
  float: left;
  padding: 19px 30px 45px 30px;
  margin: 10px 0;
  // border-radius: 8px;
  position: relative;
  border: 1px solid var(--bs-border-color);
  // box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.22);
  // background: -webkit-linear-gradient(0deg, var(--bs-secondary-bg), var(--bs-secondary-bg) 48%, var(--bs-body-bg));

  // /* For Safari 5.1 to 6.0 */
  // background: -o-linear-gradient(0deg, var(--bs-secondary-bg), var(--bs-secondary-bg) 48%, var(--bs-body-bg));

  // /* For Opera 11.1 to 12.0 */
  // background: -moz-linear-gradient(0deg, var(--bs-secondary-bg), var(--bs-secondary-bg) 48%, var(--bs-body-bg));

  // /* For Firefox 3.6 to 15 */
  // background: linear-gradient(0deg, var(--bs-secondary-bg), var(--bs-secondary-bg) 48%, var(--bs-body-bg));

  /* Standard syntax */

  &.left {
    margin-right: 10px;
  }

  &.right {
    margin-left: 10px;
  }

  .box-header .box-title {
    float: left;
    position: relative;

    h3 {
      font-size: 14px;
      font-weight: bold;
    }

    span {
      position: absolute;
      top: -11px;
      right: -33px;
      font-size: 10px;
      font-weight: 500;
      color: #ebebeb;
      background-color: #4c418b;
      line-height: 30px;
      height: 28px;
      width: 27px;
      display: inline-block;
      border-radius: 50%;
      text-align: center;
    }
  }

  .pull-right {
    position: relative;
    text-align: center;
    line-height: 10px;

    i {
      font-size: 26px;
      line-height: 24px;
      position: relative;
      cursor: pointer;
      border-radius: 50%;
      display: inline-block;
    }

    ul.dropdown-menu {
      background-color: #fff;
      color: #898989;
      border-radius: 5px;
      overflow: hidden;
      padding: 0;
      margin: 0;
      left: auto;
      right: 0;
      position: absolute;
      -webkit-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;
      visibility: hidden;
      opacity: 0;
      -webkit-transform: scaleY(0);
      -ms-transform: scaleY(0);
      transform: scaleY(0);
      display: inline-block;
      -webkit-transform-origin: top 0px;
      -ms-transform-origin: top 0px;
      transform-origin: top 0px;

      li {
        &:not(:last-child) {
          border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        }

        a {
          padding: 8px 15px;
          display: block;

          &:hover {
            background-color: #4c418b;
            color: #fff;
          }
        }
      }
    }

    &.open ul.dropdown-menu {
      -webkit-transform: scaleY(1);
      -ms-transform: scaleY(1);
      transform: scaleY(1);
      -webkit-transform-origin: top 0px;
      -ms-transform-origin: top 0px;
      transform-origin: top 0px;
      visibility: visible;
      opacity: 1;
    }

    ul.dropdown-menu.active {
      display: block;
    }
  }

  .box-content {
    width: 100%;
    height: 100%;
  }
}

.box-danger, .box-radar {
  width: calc(38% - 16px);
}

.box-statistics, .box-line {
  width: calc(62% - 4px);
}

.box-stackedColumn, .box-line {
  width: calc(62% - 20px);
  padding: 19px 30px 31px;
}

.box-spline, .box-radar {
  width: calc(38% - 0px);
}

.box-bubble, .box-map, .box-project, .box-inbox {
  width: calc(50% - 10px);
}

/* Box Danger */

.box-danger {
  height: 453px;

  .chart-doughnut .chart {
    float: left;
  }
}

/* Box Spline */

.box-spline.right {
  margin-left: 10px;
  padding: 19px 30px 31px;
}

/* Box Map */

.box-map {
  padding: 21px 30px 34px 30px;
}

/* Box Bubble */

.box-bubble {
  padding: 21px 30px 42px 30px;
}

/* Box Line */

.box-line {
  padding: 19px 30px 18px 30px;
}

/* Box Project */

.box-project, .box-inbox {
  padding: 22px 30px 19px 29px;
  height: 400px;
}

/* Box Radar */

.box-radar {
  min-height: 396px;
}

/* .box-radar .box-content canvas {
	width: 100% !important;
} */

/* Chart Tab */

.box .box-content.style1 {
  padding: 0 37px;
}

.box-content.style2 {
  padding: 0 18px;
}

ul.chart-tab {
  text-align: right;
  margin-right: 14px;
  margin-bottom: 3px;

  li {
    display: inline-block;
    font-size: 12px;
    font-weight: 400;
    font-family: "Helvetica", "Montserrat", sans-serif;
    width: 80px;
    line-height: 26px;
    border: 1px solid #898989;
    text-align: center;
    border-radius: 25px;
    margin-left: 7px;
    cursor: pointer;

    &:hover, &.active {
      background-color: #5b57a3;
      color: #ffffff;
      border-color: #5b57a3;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
    }
  }
}
