/* Main
-------------------------------------------------------------- */

main {
  float: left;
  width: calc(100% - 180px - 295px);
  // margin-top: 20px;
  margin-left: 180px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;

  &.active {
    width: calc(100% - 308px - 295px);
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  &.open {
    width: calc(100% - 180px);
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;

    &.active {
      width: calc(100% - 308px);
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
    }
  }
}

/* Rows
-------------------------------------------------------------- */

.rows {
  padding: 0 20px 20px;
}

#chartStatistics {
  height: 330px;
  width: 100%;
}

#chart-stackedColumn {
  height: 290px;
  width: 100%;
}

#lineChart {
  height: 285px;
  width: 100%;
}

#vuln-chart, #chart-spline, #bubble-chart {
  height: 270px;
  width: 100%;
}

#chartdiv {
  height: 250px;
  width: 100%;
}
