/* Vertical Navigation
-------------------------------------------------------------- */

.vertical-navigation.left {
  float: left;
  min-height: 100vh;
  position: absolute;
  z-index: 99;
  // background: -webkit-linear-gradient(180deg, var(--bs-body-bg), var(--bs-secondary-bg));

  /* Standard syntax */
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;

  .user-profile {
    padding: 35px 34px 39px;

    .user-img {
      margin-bottom: 15px;
      text-align: center;
      // margin-left: -3px;
    }

    ul.user-options {
      text-align: center;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;

      .name {
        font-size: 16px;
        font-weight: 700;
        font-family: "Helvetica", "Montserrat", sans-serif;
        color: var(--bs-body-color);
        letter-spacing: 1px;
      }

      .options {
        font-size: 10px;
        font-weight: 200;
        font-family: "Helvetica", "Montserrat", sans-serif;
        letter-spacing: 0.7px;
        padding-left: 16px;
        margin-top: 3px;
      }
    }

    .user-img {
      a {
        display: inline-block;
        position: relative;
      }

      img {
        border-radius: 50%;
      }
    }
  }

  ul.sidebar-nav {
    > li {
      text-align: center;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;

      // display: block;
      // padding: 25px 0;
      // margin-left: -7px;

      > div.img-nav {
        > {
          img {
            -webkit-transition: all 0.3s ease-in-out;
            -moz-transition: all 0.3s ease-in-out;
            -ms-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
          }

          span {
            position: absolute;
            font-family: "Helvetica", "Montserrat", sans-serif;
            top: -3px;
            right: -3px;
            background-color: #04aec6;
            color: #fff;
            font-size: 10px;
            font-weight: 400;
            height: 16px;
            width: 16px;
            line-height: 18px;
            text-align: center;
            border-radius: 50%;
          }
        }

        display: inline-block;
        position: relative;
      }

      &.active,
      &:hover {
        background-color: var(--bs-secondary-bg);
      }
    }

    li span {
      display: block;
      font-family: "Helvetica", "Montserrat", sans-serif;
      font-weight: 200;
      font-size: 14px;
      color: var(--bs-body-color);
      margin-top: 11px;
      letter-spacing: 0.5px;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
    }
  }
  @media only screen and (max-width: 767px) {
    &.active {
      width: 290px;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;

      .user-profile ul.user-options {
        text-align: center;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
      }

      ul.sidebar-nav > li {
        &.user-profile {
          text-align: center;
        }

        text-align: left;

        > div.img-nav {
          padding-left: 69px;
          margin-right: 30px;

          > img {
            -webkit-transition: all 0.3s ease-in-out;
            -moz-transition: all 0.3s ease-in-out;
            -ms-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
          }
        }
      }
    }
  }

  ul.sidebar-nav li.user-profile .user-img {
    padding: 0;
  }

  /* Vertical Navigation Active */
  @media only screen and (max-width: 767px) {
    &.active {
      ul.sidebar-nav li span {
        display: inline-block;
        font-size: 20px;
      }

      &.show {
        width: 308px;
        display: block;
        opacity: 1;
        visibility: visible;
        transform: translateX(0%);
      }
    }
  }
}
