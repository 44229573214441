
// Import Bootstrap SCSS files
@import "../node_modules/bootstrap/scss/functions";

// // Custom variable overrides
// $primary: #2e7d9d; // Example orange color
// $secondary: #2e7d9d; // Dark grey/black
// $success: #28a745; // Standard success green
// $info: #1188b8; // Standard info blue
// $warning: #ffc107; // Standard warning yellow
// $danger: #2e7d9d; // Standard danger red
// $dark: #2e7d9d; // Dark grey/black

// // Update other color variables if needed
// $body-bg: #2e7d9d; //#ffffff;
// $body-color: #3f4b5e; //#212529;
// $navbar-light-color: $primary; // Change navbar text color for light variant
// $navbar-light-active-color: darken($primary, 10%);
// $navbar-light-hover-color: darken($primary, 20%);
// $headings-color: $primary;

// Import Bootstrap variables after custom variable overrides
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/variables-dark";
@import "../node_modules/bootstrap/scss/bootstrap";


@import "bootstrap-icons/font/bootstrap-icons";
// @import "assets/scss/reset";

:root {
  --bs-body-font-family:  "Helvetica", "Montserrat", sans-serif;
}
/* You can add global styles to this file, and also import other style files */
@import url("assets/vendors/waves/waves.min.css");
@import url("assets/vendors/waves.min.css");
@import url("assets/vendors/fullcalendar.print.min.css");
@import url("assets/vendors/fullcalendar.min.css");
@import url("assets/vendors/material-design-iconic-font.min.css");

@import url("https://cdnjs.cloudflare.com/ajax/libs/MaterialDesign-Webfont/5.3.45/css/materialdesignicons.css");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.7.2/css/all.min.css");
@import url("https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i|Oswald:200,300,400,500,600,700");


@import "assets/scss/mCustomScrollbar";
@import "assets/scss/animate";


@import "assets/scss/header";
@import "assets/scss/navbar";
@import "assets/scss/modal";
@import "assets/scss/vertical_nav";
@import "assets/scss/status";
@import "assets/scss/forms";
@import "assets/scss/main";
@import "assets/scss/styles";


.canvasjs-chart-credit {
  display: none !important;
}
a {
  text-decoration: none;
}

$sidebar-width: 15rem;
$main-width: 100%;
// $sidebar-right-width: 295px;
$sidebar-right-width: 0px;

.text-left {
  text-align: left !important;
}
.btn-fill {
  width: 100%;
  margin: 0.5rem 0;
}

.vertical-navigation.left {
  width: $sidebar-width;
}

main {
  width: calc($main-width - $sidebar-width - $sidebar-right-width);
  margin-left: $sidebar-width;
}

.member-status.right {
  width: $sidebar-right-width;
}

.card {
  border: none;
}

.member-status.right .sidebar-member ul.member-list.online {
  padding-left: 2rem;
}


#sign-in-form-container {

  .form-control,
  .btn {
    width: 100%;
    margin: .5rem 0;
  }


  .form-control {
    &:focus {
      color: #727272
    }

  }



  div[data-lastpass-icon-root] {
    display: none !important;
  }


  input::placeholder {
    color: #fff;
  }
}


ngb-timepicker {
  .ngb-tp-input-container {
    input[type=text] {
      width: 100%;
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
    }
  }
}


app-configuration {
  .form-group {
    margin: 10px;
    label {
      padding-bottom: 5px;
    }
  }
}

section.tab-content {
  overflow: auto;
  table.table tbody td p {
    margin: 0.5rem 0;
  }
}

.card {
  border: 1px solid var(--bs-border-color);
  // background: linear-gradient(180deg, var(--bs-body-bg), var(--bs-secondary-bg))
}
.table-responsive {
  thead {
    th {
      // white-space: nowrap;
      text-transform: capitalize !important;
      font-size: 1rem;
      font-weight: bold;
    }
  }
}

@import "assets/scss/responsive";
@import "assets/scss/discovery-step.component";

.discovery-modes ul {
  list-style: disc;
  margin-left: 1rem;
  margin-bottom: 1rem;
}
