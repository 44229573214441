
/* Status Color */

.status-color {
  position: absolute;
  display: inline-block;
  width: 12px;
  height: 12px;
  bottom: 0px;
  right: 4px;
  z-index: 2;
  border-radius: 50%;

  &.blue {
    background: #04aec6;
    border: 3px solid var(--bs-body-bg);

    &.style2 {
      width: 12px;
      height: 12px;
      right: 6px;
      bottom: 2px;
      z-index: 2;
      border: 2px solid var(--bs-body-bg);
    }
  }

  &.green {
    background: #89e07d;
    border: 2px solid var(--bs-body-bg);
  }

  &.pink {
    background: #c73939;
    border: 2px solid var(--bs-body-bg);
  }

  &.red {
    background: #f04747;
    border: 2px solid var(--bs-body-bg);
  }

  &.grey {
    background: #c9c9c9;
    border: 2px solid var(--bs-body-bg);
  }

  &.yellow {
    background: #faa61a;
    border: 2px solid var(--bs-body-bg);
  }

  &.purple {
    background: #574c9c;
    border: 2px solid #fff;
  }
}
/* Member Status
-------------------------------------------------------------- */

.member-status {
  &.right {
    &.closed {
      width: 0;
      z-index: 99;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
    }

    float: right;
    background-color: var(--bs-body-bg);
    width: 295px;
    background: -webkit-linear-gradient(180deg, var(--bs-body-bg), var(--bs-secondary-bg));

    /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(180deg, var(--bs-body-bg), var(--bs-secondary-bg));

    /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(180deg, var(--bs-body-bg), var(--bs-secondary-bg));

    /* For Firefox 3.6 to 15 */
    background: linear-gradient(180deg, var(--bs-body-bg), var(--bs-secondary-bg));

    /* Standard syntax */
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  .sidebar-member .scroll {
    height: 900px;
    overflow: auto;
  }

  &.right .sidebar-member ul {
    &.member-tab {
      word-spacing: -4px;
      border-bottom: 2px solid #373737;

      li {
        display: inline-block;
        width: 50%;
        padding: 27px 0 20px;
        position: relative;
        text-align: center;
        cursor: pointer;

        i {
          font-size: 22px;
        }

        &.active, &:hover {
          color: #6256a9;
        }

        &.active:after {
          content: '';
          position: absolute;
          height: 2px;
          width: 100%;
          background-color: #6256a9;
          bottom: -2px;
          left: 0;
        }
      }
    }

    &.member-list {
      &.online {
        margin-top: 33px;
        padding-left: 50px;
      }

      &.offline {
        margin-top: 51px;
        padding-left: 50px;
      }

      li {
        &.member-header {
          margin-bottom: 17px;
          font-size: 14px;
          font-family: "Helvetica", "Montserrat", sans-serif;
          font-weight: 500;
          color: #bdbdbd;
          letter-spacing: 1px;
        }

        margin-bottom: 30px;

        a {
          display: block;
          color: #bdbdbd;
        }

        .avatar {
          float: left;
          height: 45px;
          width: 45px;
          margin-right: 30px;
          position: relative;

          img {
            border-radius: 50%;
          }
        }

        .info-user {
          padding-top: 5px;

          .name {
            font-size: 14px;
            font-family: "Helvetica", "Montserrat", sans-serif;
            font-weight: 500;
            color: #bdbdbd;
            letter-spacing: 0.7px;
          }

          .options {
            font-size: 10px;
            font-weight: 500;
            font-family: "Helvetica", "Montserrat", sans-serif;
            letter-spacing: 0.5px;
          }
        }
      }
    }
  }
}

.load-more a {
  font-family: "Helvetica", "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
