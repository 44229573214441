
/* Legend
-------------------------------------------------------------- */

.legend {
  ul.legend-list {
    text-align: center;

    li {
      display: inline-block;
      font-size: 10px;
      font-weight: 500;
      padding-left: 18px;
      margin: 0 10px;
      font-family: "Helvetica", "Montserrat", sans-serif;
      position: relative;
      color: #4c418b;

      &.ui {
        color: #04aec6;
      }

      &.code {
        color: #c73939;
      }

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        background-color: #4c418b;
        top: 0;
        left: 0;
        border-radius: 50%;
      }

      &.ui:before {
        background-color: #04aec6;
      }

      &.code:before {
        background-color: #c73939;
      }
    }
  }

  &.style1 {
    .legend-list {
      float: left;
      text-align: left;
      margin-top: 62px;
      width: 100%;

      li {
        display: block;
        padding: 0;
        font-size: 14px;
        font-weight: 500;
        padding-left: 20px;
        margin-bottom: 23px;
      }
    }

    ul.legend-list li:before {
      width: 12px;
      height: 12px;
      left: -6px;
      top: 2px;
    }

    .legend-list li p {
      margin-top: 5px;
      color: #898989;
      font-size: 12px;
    }
  }

  &.style2 {
    margin-top: 23px;
  }
}
