.modal{
  z-index: 99999;
  &.show {
    .modal-dialog {
      top: 50%;
    }
  }
}

.modal-backdrop.show {
  opacity: 0.85;
  z-index: 99999 !important;
}
