
/* Header
-------------------------------------------------------------- */
header {
  width: 100%;
  border-top: 3px solid var(--bs-primary-border-subtle);
}

/* Header Fix */
.header.downscrolled {
	position: fixed;
	background-color: rgba(255, 255, 255, 0.9);
	box-shadow: 0 1px 15px rgba(0, 0, 0, 0.8);
	top: 0px;
	opacity: 0;
	width: 100%;
	left: 0;
	z-index: 9999;
	-ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
	filter: alpha(opacity=0);
	visibility: hidden;
	-webkit-transform: translateY(-100%);
	-ms-transform: translateY(-100%);
	transform: translateY(-100%);
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.header.upscrolled {
	opacity: 1;
	-ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
	filter: alpha(opacity=100);
	visibility: visible;
	-webkit-transform: translateY(0);
	-ms-transform: translateY(0);
	transform: translateY(0);
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
