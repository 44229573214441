$color_1: #ebebeb;

/* Media Queries
-------------------------------------------------------------- */
/* @media only screen and (-webkit-min-device-pixel-ratio: 2) {
    .logo-normal { display: none; }
    .logo-retina { display: block; }
} */
/* Smaller than standard 1200 */
@media only screen and (max-width: 1840px) {
	.box.calendar {
		width: 100%;
	}
	#external-events.box {
		width: 100%;
		margin-top: 30px;
	}
	#calendar {
		.box.calendar {
			overflow-x: hidden;
			width: 100%;
			height: auto;
			position: relative;
			padding: 30px;
		}
	}
	#box-calendar {
		width: 900px;
		height: 100%;
		overflow: auto;
	}
	#external-events {
		.external-event {
			display: inline-block;
			width: 48%;
			margin: 0 0.5% 1.5%;
		}
	}
	tr {
		&:first-child {
			>td {
				>.fc-day-grid-event {
					font-size: 12px;
				}
			}
		}
	}
	.box-danger {
		.chart-doughnut {
			.chart {
				float: none;
				margin-right: 0;
				width: 100% !important;
				text-align: center;
			}
		}
	}
	.legend.style1 {
		.legend-list {
			margin-top: 5px;
			float: none;
			text-align: center;
			li {
				display: inline-block;
				margin: 0 12px;
				padding-left: 15px;
			}
		}
	}
	.message-info {
		.message-box {
			.message-in {
				.message-body {
					width: calc(100% - 100px);
					.message-text {
						width: auto;
					}
				}
			}
			.message-out {
				.message-body {
					width: calc(100% - 100px);
					.message-text {
						width: auto;
					}
				}
				.message-pic {
					margin-left: 30px;
				}
			}
		}
	}
	.box-project {
		.box-content {
			table {
				tr {
					td {
						&:nth-child(4) {
							width: 100px;
							padding-left: 20px;
						}
					}
				}
			}
		}
	}
}
@media only screen and (min-width: 1367px) and (max-width: 1700px) {
	.status-bar {
		ul {
			li {
				.icon {
					float: none;
					margin-right: 0;
					text-align: center;
				}
				.content {
					text-align: center;
					.numb {
						line-height: auto;
						float: none;
						vertical-align: middle;
						display: inline-block;
					}
					.text {
						vertical-align: middle;
						display: inline-block;
					}
				}
			}
		}
	}
	td {
		padding: 14px 0 14px;
	}
}
@media only screen and (max-width: 1366px) {
	.status-bar {
		ul {
			li {
				.icon {
					text-align: center;
					float: none;
					margin-right: 0;
				}
				.content {
					text-align: center;
					.numb {
						line-height: 60px;
						float: none;
						margin-right: 0;
					}
					.text {
						line-height: 50px;
					}
				}
			}
		}
	}
	// .navbar-top {
	// 	.current-menu {
	// 		>div.box-search {
	// 			margin-left: 50px;
	// 			input {
	// 				width: 300px;
	// 			}
	// 		}
	// 	}
	// }
	.legend.style1 {
		.legend-list {
			li {
				margin: 0 9px;
			}
		}
	}
	ul.chart-tab {
		margin-right: 0;
		margin-top: 5px;
	}
	.box-content.style2 {
		padding: 0px;
	}
	td {
		padding: 10px 0;
	}
	.box {
		.box-content.style1 {
			padding: 0;
		}
	}
	.box-map {
		width: 100%;
		margin-right: 0 !important;
		margin-left: 0 !important;
		margin-bottom: 20px;
	}
	.box-bubble {
		width: 100%;
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
	.box-project {
		width: 100%;
		margin-right: 0 !important;
		margin-left: 0 !important;
		margin-bottom: 20px;
	}
	.box-inbox {
		width: 100%;
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
	.box-danger {
		width: 100%;
		margin-right: 0 !important;
		margin-left: 0 !important;
		margin-bottom: 20px;
	}
	.box-radar {
		width: 100%;
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
	.box-statistics {
		width: 100%;
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
	.box-line {
		width: 100%;
		margin-right: 0 !important;
		margin-left: 0 !important;
		margin-bottom: 20px;
	}
	.box-stackedColumn {
		width: 100%;
		margin-right: 0 !important;
		margin-left: 0 !important;
		margin-bottom: 20px;
	}
	.box-spline {
		width: 100%;
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
	.box-message {
		width: 100%;
		border-radius: 0px;
		.box-content {
			height: 760px;
		}
	}
	.message-info {
		width: 100%;
		border-radius: 0px;
		.message-header {
			.move-message {
				line-height: 60px;
				height: 60px;
			}
		}
	}
}
@media only screen and (max-width: 1200px) {
	// .navbar-top {
	// 	.current-menu {
	// 		>div.logo {
  //       width: 70%;
	// 		}
	// 		div.logo.active {
  //       width: 70%;
	// 		}
	// 	}
	// 	ul.info-right {
	// 		li.user {
	// 			.info {
	// 				display: none;
	// 			}
	// 			.arrow-down {
	// 				display: none;
	// 			}
	// 			margin-right: 0;
	// 			.avatar {
	// 				margin-right: 0;
	// 			}
	// 		}
	// 	}
	// 	ul.current-menu {
	// 		li.box-search {
	// 			margin-left: 50px;
	// 		}
	// 	}
	// }
	.box-danger {
		width: 100%;
		margin-right: 0 !important;
		margin-left: 0 !important;
		margin-bottom: 20px;
	}
	.box-statistics {
		width: 100%;
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
	.box-stackedColumn {
		width: 100%;
		margin-right: 0 !important;
		margin-left: 0 !important;
		margin-bottom: 20px;
	}
	.box-spline {
		width: 100%;
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
	.box-line {
		width: 100%;
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
	.box-radar {
		width: 100%;
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
	.status-bar {
		ul {
			li {
				.content {
					.text {
						font-size: 12px;
					}
				}
			}
		}
	}
}
@media only screen and (max-width: 990px) {
	// .navbar-top {
	// 	ul.current-menu {
	// 		li.box-search {
	// 			input {
	// 				width: 100%;
	// 			}
	// 		}
	// 	}
	// 	.current-menu {
	// 		width: 100%;
	// 		>div.box-search {
	// 			width: calc(100% - 224px);
	// 			margin-right: 20px;
	// 			margin-left: 50px;
	// 			input {
	// 				width: 100%;
	// 			}
	// 		}
	// 	}
	// 	ul.info-right {
	// 		width: 100%;
	// 		text-align: center;
	// 		>li {
	// 			margin: 0 15px 15px !important;
	// 		}
	// 		li.button-menu-right {
	// 			img {
	// 				-webkit-transform: rotateY(0deg);
	// 				-ms-transform: rotateY(0deg);
	// 				transform: rotateY(0deg);
	// 			}
	// 		}
	// 		li.button-menu-right.active {
	// 			img {
	// 				-webkit-transform: rotateY(-180deg);
	// 				-ms-transform: rotateY(-180deg);
	// 				transform: rotateY(-180deg);
	// 			}
	// 		}
	// 	}
	// }
	.member-status.right {
		width: 0;
		right: 0;
		position: absolute;
		overflow: hidden;
	}
	.member-status.right.closed {
		position: absolute;
		width: 295px;
	}
	.vertical-navigation.left {
		ul.sidebar-nav {
			>li {
				margin-left: 0px;
				padding: 20px 0;
			}
		}
		.user-profile {
			padding: 25px 20px 25px;
			ul.user-options {
				display: none;
			}
			.user-img {
				margin-left: 0px;
				margin-bottom: 0px;
			}
		}
	}
	main {
		width: calc(100% - 180px);
	}
	main.active {
		width: calc(100% - 180px);
	}
	.status-bar {
		ul {
			li {
				width: 100%;
				margin-bottom: 20px;
				margin-right: 0;
			}
		}
	}
}
@media only screen and (max-width: 767px) {
	// .navbar-top {
	// 	.current-menu {
	// 		width: 100%;
	// 		>div.box-search {
	// 			width: calc(100% - 214px);
	// 			margin-left: 30px;
	// 			input {
	// 				width: 100%;
	// 			}
	// 		}
	// 	}
	// }
	#external-events {
		.external-event {
			width: 100%;
		}
	}

	main {
		width: calc(100% - 0px);
		margin-left: 0px;
	}
	main.active {
		width: calc(100% - 0px);
		margin-left: 0px;
	}
	main.open {
		width: calc(100% - 0px);
		margin-left: 0px;
	}
	main.open.active {
		width: calc(100% - 0px);
		margin-left: 0px;
	}
	.box-message {
		.box-header {
			.header-title {
				line-height: 80px;
			}
		}
	}
	#calendar {
		.box.calendar {
			padding: 15px 10px;
		}
		.footer {
			.copyright {
				margin-bottom: 15px;
				float: none;
				text-align: center;
			}
			ul.menu-ft {
				float: none;
				text-align: center;
			}
		}
	}
	#external-events.box {
		padding: 15px 10px;
	}
}
@media only screen and (max-width: 480px) {
	// .navbar-top {
	// 	.current-menu {
	// 		display: block;
	// 		>div.logo {
	// 			// float: left;
	// 		}
	// 		>div.top-button {
	// 			float: right;
	// 			margin-right: 20px;
	// 		}
	// 		>div.box-search {
	// 			clear: both;
	// 			width: 100%;
	// 			margin-left: 0;
	// 			padding: 0 20px;
	// 			line-height: 60px;
	// 			button {
	// 				top: 15px;
	// 				right: 32px;
	// 			}
	// 		}
	// 	}
	// 	ul.info-right {
	// 		>li {
	// 			margin: 15px 15px !important;
	// 		}
	// 	}
	// }
	.vertical-navigation.left.active.show {
		width: 100%;
	}
	.vertical-navigation.left.active {
		width: 100%;
	}
	.member-status.right.closed {
		width: 100%;
	}
	.box {
		padding: 20px 15px;
	}
	.box-message {
		padding: 20px 0 0px;
		.box-content {
			ul {
				li {
					padding: 40px 15px 25px 15px;
					.right {
						color: $color_1;
					}
					.left {
						float: none;
						.avatar {
							margin-right: 15px;
						}
						.content {
							float: none;
						}
					}
				}
			}
		}
	}
	.box-inbox {
		.box-content {
			ul {
				li {
					.right {
						right: 0;
					}
				}
			}
		}
		padding: 25px;
	}
	ul.chart-tab {
		li {
			width: 59px;
			margin-left: 5px;
		}
	}
	.message-info {
		.message-box {
			.mCSB_inside {
				>.mCSB_container {
					padding: 20px 10px 0;
				}
			}
			.message-in {
				.message-body {
					.message-text {
						padding: 18px;
					}
				}
			}
		}
		.message-header {
			.box-info-messager {
				padding-left: 15px;
			}
			.move-message {
				padding-right: 20px;
			}
		}
		.form-chat {
			padding: 0 15px;
			form {
				.message-form-chat {
					span.pin {
						display: none;
					}
					span.camera {
						display: none;
					}
					span.icon-message {
						display: none;
					}
					span.message-text {
						width: 100%;
					}
				}
			}
		}
	}
	.box-radar {
		min-height: 300px;
	}
	.box-project {
		padding: 25px;
		.box-content {
			table {
				tbody {
					tr.cancel {
						td.bg {
							span {
								width: calc(100% + 87px);
							}
						}
					}
				}
			}
			overflow-x: hidden;
			position: relative;
		}
	}
	.icon-mobile {
		display: block;
		ul {
			li {
				display: inline-block;
				margin-left: 15px;
			}
		}
	}
}
