/* Calendar
-------------------------------------------------------------- */

#calendar {
  .breadcrumbs {
    margin-bottom: 20px;

    li {
      display: inline-block;
      font-family: "Helvetica", "Montserrat", sans-serif;
      font-size: 16px;
    }
  }

  .box {
    padding: 65px;
  }
}

#box-calendar {
  min-width: 100%;
  height: 100%;
  overflow: auto;
  width: 920px;
}

#calendar .box .box-header {
  margin-bottom: 60px;

  h2.title {
    font-size: 46px;
    font-weight: 300;
    line-height: 46px;

    span {
      font-weight: 200;
    }
  }

  ul.date li {
    display: inline-block;
    color: #fff;
    height: 42px;
    line-height: 42px;
    padding: 0 21px;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    margin-left: 10px;
    cursor: pointer;

    &.active, &:hover {
      background: #6256a9;
      border-radius: 20px;
    }
  }
}

.fc-day-header.fc-widget-header {
  font-weight: bold;
  color: var(--bs-body-color);
  text-align: right;
  padding-right: 20px;
  padding-bottom: 15px;
  border: none;
}

.fc-day.fc-widget-content.fc-past {
  width: 14.3%;

  /* height: 100%; */
  box-shadow: 7px 10px 14px 0px rgba(0, 0, 0, 0.4);
}

td.fc-head-container.fc-widget-header {
  border-color: transparent;
  border-bottom: 1px solid #232529;
  box-shadow: 7px 10px 14px 0px rgba(0, 0, 0, 0.4);
}

.fc-body > tr > .fc-widget-content {
  border: transparent;
}

.fc-basic-view .fc-body .fc-row, td.fc-day.fc-widget-content.fc-future {
  box-shadow: 7px 10px 14px 0px rgba(0, 0, 0, 0.4);
}

.box.calendar {
  width: calc(100% - 328px);
}

#external-events {
  &.box {
    float: right;
    box-shadow: none;
    padding: 30px 20px;
    border-radius: 5px;
  }

  .external-event {
    padding: 20px 20px;
    font-weight: bold;
    margin-bottom: 20px;
    border-radius: 2px;
    color: #fff;
    cursor: move;

    &:last-child {
      margin-bottom: 0px;
    }
  }
}

.external-event {
  .event-title {
    font-size: 18px;
    font-weight: bold;
    line-height: 60px;

    span {
      font-size: 60px;
      vertical-align: middle;
      padding-right: 20px;
    }
  }

  .event-content {
    text-align: center;
    margin-top: 10px;

    .date {
      margin-bottom: 10px;
    }

    .time {
      font-weight: 400;
    }
  }

  &.bg-purple {
    background: #6256a9;
  }

  &.bg-aqua {
    background: #04aec6;
  }

  &.bg-blue {
    background: #3d5afe;
  }

  &.bg-pink {
    background: #ff4080;
  }

  &.bg-orange {
    background: #f09238;
  }
}

/* Calendar Footer */

#calendar .footer {
  padding: 30px 0;
  margin-top: 20px;
  margin-right: -20px;
  background: -webkit-linear-gradient(90deg, var(--bs-secondary-bg), var(--bs-secondary-bg) 48%, var(--bs-body-bg));
  background: -o-linear-gradient(90deg, var(--bs-secondary-bg), var(--bs-secondary-bg) 48%, var(--bs-body-bg));
  background: -moz-linear-gradient(90deg, var(--bs-secondary-bg), var(--bs-secondary-bg) 48%, var(--bs-body-bg));
  background: linear-gradient(90deg, var(--bs-secondary-bg), var(--bs-secondary-bg) 48%, var(--bs-body-bg));

  .copyright {
    float: left;

    p {
      font-size: 16px;
      font-weight: 300;
      font-family: "Helvetica", "Montserrat", sans-serif;
    }
  }

  ul.menu-ft {
    li {
      a {
        font-size: 16px;
        font-weight: 300;
        font-family: "Helvetica", "Montserrat", sans-serif;
      }

      display: inline-block;
      padding: 0 20px;
    }

    float: right;
  }
}
