/* Navbar Top
-------------------------------------------------------------- */

.navbar-top {
  position: relative;
  z-index: 9999;
  border: 1px solid var(--bs-border-color);
  // background: -webkit-linear-gradient(90deg, var(--bs-body-bg), var(--bs-secondary-bg));

  /* Standard syntax */

  .current-menu {
    float: left;
    display: inline-flex;

    > div {
      display: inline-block;

      &.logo {
        line-height: 113px;
        width: 240px;
        height: 100px;
        overflow: hidden;
        text-align: center;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;

        &.active {
          width: 308px;
          -webkit-transition: all 0.3s ease-in-out;
          -moz-transition: all 0.3s ease-in-out;
          -ms-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;
        }
      }

      &.top-button {
        display: none;
      }
      &.top-button {
        position: relative;
        cursor: pointer;
        height: 25px;
        top: 38px;
        @media only screen and (max-width: 767px) {
          display: inline-block;
        }

        span {
          height: 2px;
          width: 34px;
          display: block;
          margin-left: 10px;

          &:after {
            content: "";
            position: absolute;
            height: 2px;
            width: 33px;
            top: 11px;
            left: 1px;
            -webkit-transition: all 0.3s ease-in-out;
            -moz-transition: all 0.3s ease-in-out;
            -ms-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
          }
        }

        &.active span:after {
          left: 10px;
          -webkit-transition: all 0.3s ease-in-out;
          -moz-transition: all 0.3s ease-in-out;
          -ms-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;
        }

        span:before {
          content: "";
          position: absolute;
          height: 2px;
          width: 34px;
          top: 22px;
          left: 10px;
        }
      }

      &.box-search {
        position: relative;
        margin-left: 0px;
        line-height: 95px;

        button {
          position: absolute;
          top: 30px;
          right: 23px;
          z-index: 9;
        }

        input {
          width: 375px;
        }
      }
    }
  }

  ul.info-right {
    float: right;

    > li {
      display: inline-block;
      margin-left: 18px;
      vertical-align: middle;
      margin-top: 27px;
    }

    li {
      &.user {
        margin-left: 21px;
        margin-right: 30px;
        position: relative;
        cursor: pointer;
      }

      &.setting a,
      &.notification a {
        display: block;
        background-color: #6256a9;
        height: 43px;
        width: 43px;
        text-align: center;
        line-height: 43px;
        border-radius: 50%;
        color: #fff;
        z-index: 2;
        position: relative;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
      }

      &.setting a:hover,
      &.notification a:hover {
        background-color: #00bcd5;
      }

      &.user {
        .avatar {
          float: left;
          margin-right: 30px;
          width: 45px;
          height: 45px;
        }

        .info {
          float: left;
          margin-right: 42px;
        }

        .avatar img {
          border-radius: 50%;
        }

        .arrow-down {
          float: left;
          font-size: 20px;
          margin-top: 7px;

          .fa-angle-up {
            display: none;
          }
        }

        &.open .arrow-down {
          .fa-angle-up {
            display: block;
          }

          .fa-angle-down {
            display: none;
          }
        }

        .dropdown-menu {
          position: absolute;
          right: 0;
          left: auto;
          width: 100%;
          top: 68px;
          border-radius: 0px;
          -webkit-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;
          transition: all 0.3s ease;
          -webkit-transform: scaleY(0);
          -ms-transform: scaleY(0);
          transform: scaleY(0);
          visibility: hidden;
          opacity: 0;
          display: inline-block;
          -webkit-transform-origin: top 0px;
          -ms-transform-origin: top 0px;
          transform-origin: top 0px;
        }

        &.open .dropdown-menu {
          visibility: visible;
          opacity: 1;
          -webkit-transform: scaleY(1);
          -ms-transform: scaleY(1);
          transform: scaleY(1);
          -webkit-transform-origin: top 0px;
          -ms-transform-origin: top 0px;
          transform-origin: top 0px;
        }

        .dropdown-menu {
          &.active {
            display: block;
          }

          li {
            a {
              padding: 10px 20px;
              display: block;

              &:hover {
                background-color: #04aec6;
                color: #fff;
              }
            }

            &:first-child {
              // padding: 10px 20px;
            }

            &:not(:last-child) {
              border-bottom: 1px solid rgba(0, 0, 0, 0.15);
            }
          }

          ul li {
            .avatar {
              margin-right: 15px;
            }

            .profile {
              margin: 12px 0 0;
              float: left;

              a {
                padding: 5px 9px;
                background: #6256a9;
                border-radius: 5px;
                color: #fff;

                &:hover {
                  background-color: #04aec6;
                }
              }
            }
          }
        }

        .info {
          .name {
            font-family: "Helvetica", "Montserrat", sans-serif;
            font-size: 16px;
            font-weight: 500;
            color: var(--bs-body-color);
            margin-top: 2px;
            letter-spacing: 0.8px;
          }

          .address {
            font-size: 10px;
            font-family: "Helvetica", "Montserrat", sans-serif;
            margin-top: 1px;
            margin-left: 2px;
            letter-spacing: 0.5px;
            text-transform: uppercase;
          }
        }
      }

      &.button-menu-right {
        margin-right: 40px;
        cursor: pointer;

        img {
          -webkit-transform: rotateY(-180deg);
          -ms-transform: rotateY(-180deg);
          transform: rotateY(-180deg);
          -webkit-transition: all 0.3s ease-in-out;
          -moz-transition: all 0.3s ease-in-out;
          -ms-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;
        }

        &.active img {
          -webkit-transform: rotateY(0deg);
          -ms-transform: rotateY(0deg);
          transform: rotateY(0deg);
          -webkit-transition: all 0.3s ease-in-out;
          -moz-transition: all 0.3s ease-in-out;
          -ms-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;
        }
      }
    }
  }
}
